import React from 'react'
import { match } from 'ts-pattern'
import styles from './Icon.module.scss'

export enum Icons {
  Arrow = 'Arrow',
  BackArrow = 'BackArrow',
  BigFive = 'BigFive',
  Blind = 'Blind',
  BooksApple = 'BooksApple',
  Brain = 'Brain',
  Branch = 'Branch',
  Briefcase = 'Briefcase',
  Bubble = 'Bubble',
  Caret = 'Caret',
  Chevron = 'Chevron',
  Calendar = 'Calendar',
  CalendarDetailed = 'CalendarDetailed',
  Chat = 'Chat',
  Checkmark = 'Checkmark',
  CheckmarkCircle = 'CheckmarkCircle',
  CheckmarkCircleMasked = 'CheckmarkCircleMasked',
  CheckMarkOutOfCircle = 'CheckMarkOutOfCircle',
  Clipboard = 'Clipboard',
  Close = 'Close',
  Coffee = 'Coffee',
  Compass = 'Compass',
  Computer = 'Computer',
  Handshake = 'Handshake',
  Conversation = 'Conversation',
  TwoSpeechBubbles = 'TwoSpeechBubbles',
  Copy = 'Copy',
  Diamond = 'Diamond',
  Disc = 'Disc',
  DiscGray = 'DiscGray',
  Dollar = 'Dollar',
  DollarSign = 'DollarSign',
  Dog = 'Dog',
  Download = 'Download',
  DownwardTrend = 'DownwardTrend',
  Dumbbell = 'Dumbbell',
  Edit = 'Edit',
  Ellipsis = 'Ellipsis',
  Email = 'Email',
  EmailGray = 'EmailGray',
  Enneagram = 'Enneagram',
  Eye = 'Eye',
  EyeCrossedOut = 'EyeCrossedOut',
  Filter = 'Filter',
  Flower = 'Flower',
  FourSquare = 'FourSquare',
  Gavel = 'Gavel',
  Gear = 'Gear',
  GearCheck = 'GearCheck',
  Group = 'Group',
  GlassHalfFull = 'GlassHalfFull',
  GroupSparkle = 'GroupSparkle',
  GroupThink = 'GroupThink',
  GroupWave = 'GroupWave',
  Hamburger = 'Hamburger',
  HandHeart = 'HandHeart',
  HandTalk = 'HandTalk',
  HandWave = 'HandWave',
  HeartArrow = 'HeartArrow',
  HeartFlower = 'HeartFlower',
  Directions = 'Directions',
  Laptop = 'Laptop',
  HeartHandshake = 'HeartHandshake',
  HeartRate = 'HeartRate',
  Home = 'Home',
  Idea = 'Idea',
  Info = 'Info',
  Journal = 'Journal',
  Lightbulb = 'Lightbulb',
  Lightning = 'Lightning',
  Lion = 'Lion',
  Link = 'Link',
  LinkedIn = 'LinkedIn',
  LinkedInGray = 'LinkedInGray',
  Lock = 'Lock',
  Magic = 'Magic',
  MagnifyingGlass = 'MagnifyingGlass',
  Megaphone = 'Megaphone',
  Microphone = 'Microphone',
  MinimalLock = 'MinimalLock',
  Monitor = 'Monitor',
  Mountain = 'Mountain',
  NewsPaper = 'NewsPaper',
  Negotiate = 'Negotiate',
  Nodes = 'Nodes',
  Paint = 'Paint',
  Peers = 'Peers',
  People = 'People',
  PeopleThree = 'PeopleThree',
  Person = 'Person',
  PersonalityTypes16 = 'PersonalityTypes16',
  PersonCenter = 'PersonCenter',
  PersonCheckmark = 'PersonCheckmark',
  Playbook = 'Playbook',
  PlaybookScale = 'PlaybookScale',
  Plus = 'Plus',
  Podium = 'Podium',
  Print = 'Print',
  PuzzlePiece = 'PuzzlePiece',
  Refresh = 'Refresh',
  Scale = 'Scale',
  Search = 'Search',
  SearchGray = 'SearchGray',
  SearchProfile = 'SearchProfile',
  Signature = 'Signature',
  SignatureBox = 'SignatureBox',
  Smile = 'Smile',
  Star = 'Star',
  Tada = 'Tada',
  Tag = 'Tag',
  TagHanging = 'TagHanging',
  Team = 'Team',
  ThumbsUp = 'ThumbsUp',
  ThumbsDown = 'ThumbsDown',
  Tools = 'Tools',
  Trash = 'Trash',
  Trophy = 'Trophy',
  Trees = 'Trees',
  TwoFingersUp = 'TwoFingersUp',
  User = 'User',
  UserGray = 'UserGray',
  Users = 'Users',
  Verified = 'Verified',
  Warning = 'Warning',
  Weight = 'Weight',
  Whistle = 'Whistle',
  Wrench = 'Wrench',
}

export type Props = {
  icon: Icons | undefined | null
  className?: string
  style?: React.CSSProperties
}

export default function Icon({ icon, className = '', style = {} }: Props) {
  const IconComponent = match(icon)
    .with(Icons.Disc, () => (
      <svg viewBox="0 0 30 30">
        <g className="circle">
          <circle style={{ fill: 'none' }} cx="14.72" cy="14.97" r="14" />
          <circle cx="14.72" cy="14.97" r="12.88" />
        </g>
        <g className="inner-circle">
          <circle cx="14.72" cy="14.97" r="3.92" />
          <circle cx="14.72" cy="14.97" r="2.8" />
        </g>
        <g className="lines">
          <line x1="14.72" y1="11.61" x2="14.72" y2="1.53" />
          <line x1="14.72" y1="28.41" x2="14.72" y2="18.33" />
          <line x1="18.08" y1="14.97" x2="28.16" y2="14.97" />
          <line x1="1.28" y1="14.97" x2="11.36" y2="14.97" />
        </g>
      </svg>
    ))
    .with(Icons.LinkedIn, () => (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="linkedin" clipPath="url(#clip0_270_1238)">
          <path
            id="linkedin_2"
            d="M17.04 17.794H14.078V13.15C14.078 12.042 14.055 10.619 12.535 10.619C10.991 10.619 10.755 11.819 10.755 13.069V17.792H7.793V8.24999H10.638V9.54999H10.676C10.9613 9.0633 11.3731 8.66302 11.8677 8.39168C12.3624 8.12034 12.9212 7.98809 13.485 8.00899C16.485 8.00899 17.041 9.98399 17.041 12.555V17.79L17.04 17.794ZM4.448 6.94399C4.10786 6.94399 3.77535 6.84314 3.49252 6.65419C3.20969 6.46524 2.98924 6.19667 2.85902 5.88244C2.72881 5.56821 2.69469 5.22242 2.76098 4.8888C2.82726 4.55518 2.99098 4.24871 3.23142 4.00812C3.47187 3.76753 3.77825 3.60364 4.11183 3.53716C4.44541 3.47068 4.79122 3.5046 5.10552 3.63463C5.41983 3.76466 5.68853 3.98496 5.87764 4.26768C6.06676 4.5504 6.1678 4.88285 6.168 5.22299C6.16826 5.44899 6.12396 5.67281 6.03763 5.88167C5.9513 6.09052 5.82463 6.28031 5.66487 6.44015C5.50511 6.6 5.31541 6.72678 5.1066 6.81324C4.8978 6.89969 4.67399 6.94412 4.448 6.94399ZM5.933 17.794H2.963V8.24999H5.933V17.794ZM18.52 0.749991H1.475C1.28353 0.747745 1.09349 0.783238 0.915738 0.854442C0.737986 0.925646 0.575999 1.03117 0.43903 1.16498C0.302061 1.29879 0.192792 1.45827 0.117463 1.63432C0.0421336 1.81036 0.0022195 1.99952 0 2.19099L0 19.309C0.0022195 19.5005 0.0421336 19.6896 0.117463 19.8657C0.192792 20.0417 0.302061 20.2012 0.43903 20.335C0.575999 20.4688 0.737986 20.5743 0.915738 20.6455C1.09349 20.7167 1.28353 20.7522 1.475 20.75H18.519C18.9063 20.7548 19.2798 20.6058 19.5574 20.3357C19.835 20.0655 19.9942 19.6963 20 19.309V2.19099C19.9942 1.80367 19.835 1.43445 19.5574 1.16432C19.2798 0.894183 18.9063 0.745182 18.519 0.749991H18.52Z"
            fill="#1E749F"
            stroke="none"
          />
        </g>
        <defs>
          <clipPath id="clip0_270_1238">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.75)"
            />
          </clipPath>
        </defs>
      </svg>
    ))
    .with(Icons.Plus, () => (
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_386_10755)">
          <path
            d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 5.71436V25.033"
            stroke="#80949E"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.6595 15.3738H6.34082"
            stroke="#80949E"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_386_10755"
            x="0"
            y="0"
            width="33"
            height="33"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.00392157 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_386_10755"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_386_10755"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    ))
    .with(Icons.Journal, () => (
      <svg
        width="29"
        height="33"
        viewBox="0 0 29 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_323_2219)">
          <path
            d="M26.3946 1.63379H21.8552H4.61838L3.23682 1.00879V30.0219L4.61838 31.0087H26.3946V11.5022V1.63379Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.0131 12.7192H11.4604V16.6995H20.0131V12.7192Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.13156 7.58777C6.13156 8.24567 4.98027 8.77198 3.5658 8.77198C2.15133 8.77198 1 8.24567 1 7.58777C1 6.92988 1.78946 6.40356 3.20393 6.40356"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.13156 15.7128C6.13156 16.3707 4.98027 16.897 3.5658 16.897C2.15133 16.897 1 16.3707 1 15.7128C1 15.0549 1.78946 14.5286 3.20393 14.5286"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.13156 23.8375C6.13156 24.4954 4.98027 25.0217 3.5658 25.0217C2.15133 25.0217 1 24.4954 1 23.8375C1 23.1796 1.78946 22.6533 3.20393 22.6533"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3945 11.5024V1.63403"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.61865 1.63403V31.009"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_323_2219"
            x="0"
            y="0.00878906"
            width="28.3945"
            height="33.0002"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.00392157 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_323_2219"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_323_2219"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    ))
    .with(Icons.Users, () => (
      <svg
        width="55"
        height="33"
        viewBox="0 0 55 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_323_2261)">
          <path
            d="M36.1354 21.1267C37.6544 20.2587 39.6073 19.8247 42.0485 19.8247C47.7447 19.8247 51.3795 23.1882 52.7357 27.8536C53.1697 29.4269 51.9762 31.0001 50.3487 31.0001H33.6941C32.0667 31.0001 30.8731 29.4269 31.3071 27.8536"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5184 27.7994C22.9524 29.3727 21.759 30.9459 20.1315 30.9459H3.47685C1.84937 30.9459 0.655861 29.3727 1.08986 27.7994C2.4461 23.1882 5.21287 19.7705 11.7771 19.7705C13.947 19.7705 15.7915 20.2588 17.3105 21.0725"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.4298 27.7989H18.7752C17.1477 27.7989 15.9542 26.2257 16.3882 24.6525C17.7444 20.0413 20.5111 16.6235 27.0753 16.6235C32.7715 16.6235 36.4063 19.987 37.7625 24.6525C38.2508 26.2257 37.0573 27.7989 35.4298 27.7989Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.8261 6.15371C32.8261 9.08319 30.9273 13.4774 27.3469 13.4774C23.7664 13.4774 21.8677 9.13744 21.8677 6.15371C22.0304 3.11573 24.2546 1 27.3469 1C30.1136 1 32.7718 2.73599 32.8261 6.15371Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.7988 9.3002C47.7988 12.2297 45.9 16.6239 42.3195 16.6239C38.7391 16.6239 36.8403 12.2839 36.8403 9.3002C37.0031 6.26222 39.2273 4.14648 42.3195 4.14648C45.0863 4.14648 47.7445 5.88247 47.7988 9.3002Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5272 9.3002C17.5272 12.2297 15.6285 16.6239 12.048 16.6239C8.46757 16.6239 6.56885 12.2839 6.56885 9.3002C6.7316 6.26222 8.95582 4.14648 12.048 4.14648C14.869 4.14648 17.5272 5.88247 17.5272 9.3002Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_323_2261"
            x="-0.000488281"
            y="0"
            width="54.8267"
            height="33"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.00392157 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_323_2261"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_323_2261"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    ))
    .with(Icons.User, () => (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 5053">
          <path
            id="Ellipse 55 1"
            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <mask
            id="mask0_41_16498"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="20"
            height="20"
          >
            <path
              id="Ellipse 55 2"
              d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_41_16498)">
            <g id="Group">
              <path
                id="Vector"
                d="M3.45847 17.5422C3.62513 15.0422 4.00013 14.8338 4.20846 14.6672C5.08346 14.0422 6.08346 13.5838 7.1668 13.3755C9.1668 15.4588 12.5001 15.5005 14.5835 13.5005L14.7085 13.3755C15.7918 13.5838 16.7918 14.0422 17.6668 14.6672C17.9168 14.8338 18.3335 15.0005 18.5001 17.6255"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_2"
                d="M14.7919 7.37541C14.7919 9.45874 13.4585 12.5421 10.9169 12.5421C8.37521 12.5421 7.04187 9.45874 7.04187 7.37541C7.16687 5.25041 9.00021 3.62541 11.1252 3.75041C13.1252 3.83374 14.6669 5.41707 14.7919 7.37541Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Filter, () => (
      <svg viewBox="0 0 20 19" height="20" width="19">
        <path d="M11.227 16.3781L8.12778 17.3927C7.96615 17.4456 7.8 17.3252 7.8 17.1551V10.3608C7.8 10.0985 7.69697 9.84673 7.51312 9.65971L1.50837 3.55157C0.57585 2.60298 1.24786 1 2.57805 1H17.2415C18.5967 1 19.2571 2.65473 18.2743 3.58782L11.8829 9.65581C11.684 9.84462 11.5714 10.1068 11.5714 10.381V15.9029C11.5714 16.1191 11.4325 16.3108 11.227 16.3781Z" />
      </svg>
    ))
    .with(Icons.Megaphone, () => (
      <svg
        width="47"
        height="33"
        viewBox="0 0 47 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_323_2236)">
          <path
            d="M35.6831 10.2736L42.9165 4.6167"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.6831 18.0632L42.9165 23.7201"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.0806 14.0293H44.7251"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.2582 1H26.4561V28.2643H30.2582V1Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.78207 19.5471H1V11.1545H3.78207"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5393 21.68L16.9504 30.9999H10.4589L7.8623 21.1699"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.4558 27.0125C19.9643 20.521 5.59022 20.6601 5.59022 20.6601C4.70923 20.3819 4.15283 19.5936 4.15283 18.7126V11.6183C4.15283 10.691 4.70925 9.71725 5.63661 9.62452C11.108 9.11447 18.3414 10.9692 26.4558 2.4375V27.0125Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.4558 27.0125C19.9643 20.521 5.59022 20.6601 5.59022 20.6601C4.70923 20.3819 4.15283 19.5936 4.15283 18.7126V11.6183C4.15283 10.691 4.70925 9.71725 5.63661 9.62452C11.108 9.11447 18.3414 10.9692 26.4558 2.4375V27.0125Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_323_2236"
            x="0"
            y="0"
            width="46.7251"
            height="33"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.00392157 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_323_2236"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_323_2236"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    ))
    .with(Icons.Caret, () => (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 8L7 5L4 2"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Chevron, () => (
      <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          d="M3.5 8.5L6.5 5.5L3.5 2.5"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 8.5L9.5 5.5L6.5 2.5"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Person, () => (
      <svg viewBox="0 0 30 30">
        <path d="M26.27,28V25.08A5.79,5.79,0,0,0,20.5,19.3H8.94a5.8,5.8,0,0,0-5.78,5.78V28" />
        <circle cx="14.72" cy="7.74" r="5.78" />
      </svg>
    ))
    .with(Icons.HeartRate, () => (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.3998 12.1604H19.0798L15.8798 21.8404L9.3198 2.40039L6.1198 12.1604H1.7998"
          stroke="#1E749F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Home, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
      >
        <path
          d="M17.3333 7.33337V17.5873H4.66666V7.36931"
          stroke="#80949e"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 8.82759L11 2L20 8.82759"
          stroke="#80949e"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Eye, () => (
      <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6 4.7998C6.23406 4.79981 2.39393 9.85142 1.31468 11.4919C1.1116 11.8006 1.12445 12.1907 1.34628 12.4862C2.51459 14.0427 6.5779 18.7998 12.6 18.7998C18.6221 18.7998 22.6854 14.0427 23.8537 12.4862C24.0755 12.1907 24.0884 11.8006 23.8853 11.4919C22.8061 9.85141 18.9659 4.7998 12.6 4.7998Z"
          stroke="#1E749F"
          strokeWidth="2"
        />
        <path
          d="M16.0002 11.6002C16.0002 13.478 14.478 15.0002 12.6002 15.0002C10.7224 15.0002 9.2002 13.478 9.2002 11.6002C9.2002 9.72243 10.7224 8.2002 12.6002 8.2002C14.478 8.2002 16.0002 9.72243 16.0002 11.6002Z"
          stroke="#1E749F"
          strokeWidth="2"
        />
      </svg>
    ))
    .with(Icons.EyeCrossedOut, () => (
      <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6 4.92383C6.23407 4.92383 2.39393 9.97544 1.31468 11.616C1.1116 11.9247 1.12445 12.3147 1.34628 12.6103C2.51459 14.1667 6.5779 18.9238 12.6 18.9238C18.6221 18.9238 22.6854 14.1667 23.8537 12.6103C24.0755 12.3147 24.0884 11.9247 23.8853 11.616C22.8061 9.97544 18.9659 4.92382 12.6 4.92383Z"
          stroke="#1E749F"
          strokeWidth="2"
        />
        <path
          d="M16.0002 11.7242C16.0002 13.602 14.478 15.1242 12.6002 15.1242C10.7224 15.1242 9.2002 13.602 9.2002 11.7242C9.2002 9.84645 10.7224 8.32422 12.6002 8.32422C14.478 8.32422 16.0002 9.84645 16.0002 11.7242Z"
          stroke="#1E749F"
          strokeWidth="2"
        />
        <path
          d="M19.7275 4.92383L5.89212 18.7593"
          stroke="#1E749F"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ))
    .with(Icons.Peers, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_834_1135)">
          <path
            d="M11 15.6669V14.4002C11 13.0002 9.86666 11.8002 8.39999 11.8002H3.26665C1.86665 11.8002 0.666656 12.9335 0.666656 14.4002V15.6669"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.66674 9.19995C7.10268 9.19995 8.26673 8.03588 8.26673 6.59994C8.26673 5.16399 7.10268 4 5.66674 4C4.2308 4 3.06674 5.16399 3.06674 6.59994C3.06674 8.03588 4.2308 9.19995 5.66674 9.19995Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.8666 15.6666V14.3999C14.8666 13.1999 14.0666 12.1999 12.9333 11.9332"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.7335 15.6666V14.3999C18.7335 13.1999 17.9335 12.1999 16.8001 11.9332"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.3333 4.13342C11.7333 4.46676 12.5333 5.86672 12.2 7.26672C12 8.20006 11.2667 8.86676 10.3333 9.13342"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.1999 4.13342C15.5999 4.46676 16.3999 5.86672 16.0665 7.26672C15.8665 8.20006 15.1332 8.86676 14.1999 9.13342"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_834_1135">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ))
    .with(Icons.People, () => (
      <svg viewBox="0 0 30 30">
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
    ))
    .with(Icons.PeopleThree, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
        <path
          d="M16.5 24.0354V22.1354C16.5 20.0354 14.8 18.2354 12.6 18.2354H4.89999C2.79999 18.2354 1 19.9354 1 22.1354V24.0354"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.50009 14.3351C10.654 14.3351 12.4001 12.589 12.4001 10.4351C12.4001 8.28115 10.654 6.53516 8.50009 6.53516C6.34618 6.53516 4.6001 8.28115 4.6001 10.4351C4.6001 12.589 6.34618 14.3351 8.50009 14.3351Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.2999 24.0356V22.1356C22.2999 20.3356 21.0999 18.8355 19.3999 18.4355"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.1002 24.0356V22.1356C28.1002 20.3356 26.9002 18.8355 25.2002 18.4355"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 6.73535C17.6 7.23535 18.8 9.3353 18.3 11.4353C18 12.8353 16.9 13.8354 15.5 14.2354"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2998 6.73535C23.3998 7.23535 24.5998 9.3353 24.0998 11.4353C23.7998 12.8353 22.6998 13.8354 21.2998 14.2354"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Lightning, () => (
      <svg viewBox="0 0 30 30">
        <path d="M14.58,3.07,4.12,17.79H15.67l-2.55,10.7,14.6-17H15.92Z" />
      </svg>
    ))
    .with(Icons.DownwardTrend, () => (
      <svg viewBox="0 0 30 30">
        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
        <polyline points="17 18 23 18 23 12" />
      </svg>
    ))
    .with(Icons.Dumbbell, () => (
      <svg viewBox="0 0 30 30">
        <rect
          x="4.55"
          y="16.46"
          width="5.59"
          height="12.34"
          transform="translate(-13.85 11.82) rotate(-45)"
        />
        <rect
          x="19.59"
          y="1.32"
          width="5.59"
          height="12.34"
          transform="translate(1.26 18.02) rotate(-45)"
        />
        <line x1="7.72" y1="17.97" x2="18.73" y2="7.8" />
        <line x1="21.72" y1="11.97" x2="11.72" y2="21.97" />
      </svg>
    ))
    .with(Icons.Blind, () => (
      <svg viewBox="0 0 30 30">
        <path d="M26.84,27.83V24.94a5.8,5.8,0,0,0-5.78-5.78H9.5a5.8,5.8,0,0,0-5.78,5.78v2.89" />
        <circle data-name="Ellipse 314-2" cx="15.22" cy="8.33" r="5.5" />
        <line data-name="Line 380" x1="10.29" y1="8.48" x2="20.44" y2="8.48" />
      </svg>
    ))
    .with(Icons.Print, () => (
      <svg viewBox="0 0 30 30">
        <polyline points="7.12 10.42 7.12 0.97 23.32 0.97 23.32 10.42" />
        <path d="M7.12,22.57H4.42a2.7,2.7,0,0,1-2.7-2.7V13.12a2.7,2.7,0,0,1,2.7-2.7H26a2.7,2.7,0,0,1,2.7,2.7v6.75a2.7,2.7,0,0,1-2.7,2.7h-2.7" />
        <rect x="7.12" y="17.17" width="16.2" height="10.8" />
      </svg>
    ))
    .with(Icons.PuzzlePiece, () => (
      <svg viewBox="0 0 30 30">
        <g>
          <path d="M27.37,8h0a.53.53,0,0,0-.25,0h-6a4,4,0,0,0,.57-2,4.05,4.05,0,1,0-8.1,0h0a4.06,4.06,0,0,0,.57,2h-6a.37.37,0,0,0-.39.32v6.37a4.1,4.1,0,0,0-2-.57,4.05,4.05,0,1,0,0,8.1h0a3.9,3.9,0,0,0,2-.57v6s0,0,0,0c0,.42.17.61.38.61h18.9a.68.68,0,0,0,.52-.26c.3-.05.44-.19.44-.36V8.64A.67.67,0,0,0,27.37,8Z" />
          <g id="Group_3973" data-name="Group 3973">
            <g id="Group_3972" data-name="Group 3972">
              <g id="Path_2752" data-name="Path 2752">
                <path d="M27.73,20.15Zm-3.6-.66a1.54,1.54,0,1,1,0-3.08,1.65,1.65,0,0,1,1.3.62h0a1.67,1.67,0,0,0,1.31.63,1.6,1.6,0,0,0,1-.31h0v1.08a3.32,3.32,0,0,0-1-.21h0a1.66,1.66,0,0,0-1.31.63h0A1.64,1.64,0,0,1,24.13,19.49Z" />
                <path d="M24.13,17.41a.55.55,0,1,0,0,1.08.61.61,0,0,0,.51-.25h0a2.5,2.5,0,0,1,.27-.29,2.55,2.55,0,0,1-.26-.3h0a.66.66,0,0,0-.51-.23m0-2a2.62,2.62,0,0,1,2.09,1,.65.65,0,0,0,.52.25.66.66,0,0,0,.4-.13c.16-.11,1.59-.89,1.59-1.09v4.7c0-.35-1.62-.93-2-.93h0a.65.65,0,0,0-.52.25,2.63,2.63,0,0,1-2.09,1,2.54,2.54,0,1,1,0-5.08Zm4.6,4.74Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(Icons.ThumbsUp, () => (
      <svg viewBox="0 0 24 24">
        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
      </svg>
    ))
    .with(Icons.ThumbsDown, () => (
      <svg viewBox="0 0 24 24">
        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
      </svg>
    ))
    .with(Icons.Checkmark, () => (
      <svg viewBox="0 0 24 24">
        <polygon points="9.9 18.4 3.9 12.4 5.6 10.8 9.9 15 18.2 6.7 19.9 8.4 9.9 18.4" />
      </svg>
    ))
    .with(Icons.Calendar, () => (
      <svg viewBox="0 0 30 30">
        <rect x="3.57" y="3.67" width="24.3" height="24.3" rx="2.7" />
        <line x1="21.12" y1="0.97" x2="21.12" y2="6.37" />
        <line x1="10.32" y1="0.97" x2="10.32" y2="6.37" />
        <line x1="3.57" y1="11.77" x2="27.87" y2="11.77" />
      </svg>
    ))
    .with(Icons.Ellipsis, () => (
      <svg viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
    ))
    .with(Icons.Email, () => (
      <svg viewBox="0 0 30 30">
        <path d="M4.42,4.17H26a2.7,2.7,0,0,1,2.7,2.7v16.2a2.7,2.7,0,0,1-2.7,2.7H4.42a2.7,2.7,0,0,1-2.7-2.7V6.87A2.7,2.7,0,0,1,4.42,4.17Z" />
        <path d="M28.72,6.87l-13.5,9.45L1.72,6.87" />
      </svg>
    ))
    .with(Icons.Briefcase, () => (
      <svg viewBox="0 0 30 30">
        <rect x="1.72" y="8.47" width="26" height="18.2" rx="2.6" />
        <path d="M19.92,26.67V5.87a2.6,2.6,0,0,0-2.6-2.6h-5.2a2.59,2.59,0,0,0-2.6,2.6v20.8" />
      </svg>
    ))
    .with(Icons.Compass, () => (
      <svg viewBox="0 0 30 30">
        <circle cx="14.72" cy="14.83" r="13" />
        <path d="M20.23,9.32l-2.76,8.27L9.21,20.34,12,12.07Z" />
      </svg>
    ))
    .with(Icons.Download, () => (
      <svg viewBox="0 0 30 30">
        <path d="M28.72,19.16v6.09a3,3,0,0,1-3,3.05H4.36a3.06,3.06,0,0,1-3.05-3.05V19.16" />
        <polyline points="7.4 14.62 15.02 22.24 22.63 14.62" />
        <line x1="15.02" y1="22.24" x2="15.02" y2="1.91" />
      </svg>
    ))
    .with(Icons.Chat, () => (
      <svg viewBox="0 0 30 30">
        <path d="M28.72,19.47a3,3,0,0,1-3,3h-18l-6,6v-24a3,3,0,0,1,3-3h21a3,3,0,0,1,3,3Z" />
      </svg>
    ))
    .with(Icons.Microphone, () => (
      <svg viewBox="0 0 30 30">
        <path d="M15.55,1.07a3.78,3.78,0,0,0-3.78,3.78V15a3.79,3.79,0,1,0,7.57,0V4.85A3.79,3.79,0,0,0,15.55,1.07Z" />
        <path d="M24.39,12.42V15A8.84,8.84,0,0,1,6.72,15V12.42" />
        <line x1="15.72" y1="23.83" x2="15.72" y2="28.83" />
        <line x1="10.72" y1="28.83" x2="20.72" y2="28.83" />
      </svg>
    ))
    .with(Icons.Computer, () => (
      <svg viewBox="0 0 30 30">
        <rect x="1.72" y="1.97" width="27" height="18.9" rx="2.7" />
        <line x1="9.82" y1="26.27" x2="20.62" y2="26.27" />
        <line x1="15.22" y1="20.87" x2="15.22" y2="26.27" />
      </svg>
    ))
    .with(Icons.Signature, () => (
      <svg viewBox="0 0 30 30">
        <path d="M5,3.91,17.6,21.22l3.32,1.25.19-3.58L8.83,1.27S5.45.56,5,3.91Z" />
        <line x1="3.72" y1="27.55" x2="27.17" y2="27.55" />
        <line x1="3.72" y1="17.74" x2="8.97" y2="22.99" />
        <line x1="8.97" y1="17.74" x2="3.72" y2="22.99" />
      </svg>
    ))
    .with(Icons.SignatureBox, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="124"
        height="80"
        fill="none"
        viewBox="0 0 124 80"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M122.317 2.29492H2.29492V77.9277H122.317V2.29492Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M2.29492 24.0522H122.446"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18.2197 67.6968C21.3271 57.9837 24.305 48.2706 27.4124 38.5575C26.6355 46.4575 26.3766 54.3575 26.765 62.2575C28.3187 59.4083 30.2608 56.8181 32.5913 54.487C33.1092 56.5591 33.6271 58.6312 34.2745 60.7034C34.6629 62.128 35.1808 63.6821 36.605 63.9411C37.8997 64.2001 38.9355 63.164 39.7124 62.2575C41.3955 60.1853 42.8197 57.7247 43.5966 55.1345C42.1724 57.3362 43.8555 60.7034 46.445 60.8329C48.5166 60.9624 50.3292 59.2788 51.6239 57.7247C57.4502 50.9903 61.5934 42.9607 63.665 34.2837C60.9461 39.9821 60.2987 46.587 61.7229 52.6739C62.2408 55.005 63.1471 57.2067 64.8303 58.7608C65.3481 59.1493 65.9955 59.5378 66.6429 59.6673C68.326 59.7968 69.4913 58.1132 70.1387 56.5591C72.9871 50.3427 74.5407 43.4788 74.5407 36.4853C73.246 41.2771 72.7281 46.328 72.8576 51.3788C72.9871 54.3575 73.8934 57.9837 76.8713 58.6312C78.8134 59.0198 80.7555 57.7247 82.3092 56.4296C85.2871 54.0985 88.265 51.7673 91.2429 49.4362C91.6313 49.1771 92.0197 48.7886 92.5376 49.0476C92.7966 49.1771 92.9261 49.4362 93.0555 49.6952C93.9619 51.5083 93.5734 53.8394 92.2787 55.3935C92.0197 55.6525 91.8903 55.7821 91.5018 55.9116C90.5955 56.1706 87.6176 53.7099 88.006 52.8034C88.3945 51.8968 91.5019 49.3067 92.4082 49.0476C96.0334 48.1411 99.9176 50.6017 103.543 49.5657"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M26.1177 13.4331H98.7524"
        />
      </svg>
    ))
    .with(Icons.Tag, () => (
      <svg viewBox="0 0 30 30">
        <circle cx="8.59" cy="8.69" r="2.54" />
        <path d="M28.77,17.83,17.73,29,1.87,13.13,2.38,2.6,12.91,2Z" />
      </svg>
    ))
    .with(Icons.TagHanging, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="95"
        fill="none"
        viewBox="0 0 96 95"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M94.2495 52.6L57.6494 89.3L7.94971 39.6L10.2495 5.89999L44.6494 3L94.2495 52.6Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19.9497 18.8001C21.6618 18.8001 23.0498 17.4122 23.0498 15.7001C23.0498 13.988 21.6618 12.6001 19.9497 12.6001C18.2376 12.6001 16.8496 13.988 16.8496 15.7001C16.8496 17.4122 18.2376 18.8001 19.9497 18.8001Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19.0499 15.7C14.2499 14.5 2.34953 20.2 1.14953 32C-2.15047 63.6 50.5499 65 23.0499 92.8"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M46.4497 57.9998C55.8938 57.9998 63.5498 50.3439 63.5498 40.8998C63.5498 31.4557 55.8938 23.7998 46.4497 23.7998C37.0056 23.7998 29.3496 31.4557 29.3496 40.8998C29.3496 50.3439 37.0056 57.9998 46.4497 57.9998Z"
        />
      </svg>
    ))
    .with(Icons.Tools, () => (
      <svg
        width="96"
        height="88"
        viewBox="0 0 96 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M67.1738 15.1L72.9739 8.9L64.5737 1L45.1738 21.7L53.5737 29.6L59.7739 23"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.4739 12.7002L85.9739 28.2002L89.4739 31.5002C96.8739 38.5002 95.4738 47.1002 89.3738 53.6002C85.5738 43.5002 77.2739 37.4002 77.2739 37.4002L73.6738 41.2002L57.1738 25.8002"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9738 78.0998L62.3737 30.7998L69.4738 37.3998L24.4738 85.2998C22.5738 87.2998 19.3738 87.4998 17.2738 85.5998C15.0738 83.5998 14.8738 80.1998 16.9738 78.0998Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.0732 61.2002L66.5732 84.2002C68.3732 86.3002 71.5734 86.7002 73.7734 84.9002C76.0734 83.1002 76.3734 79.7002 74.4734 77.4002L54.0732 53.7002"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.4739 46.2998L31.5737 27.7998L24.1738 34.0998L40.5737 53.4998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0734 3.80007L17.7736 8.70007L19.8735 17.1001L13.6735 23.1001L5.37348 20.7001L1.27363 16.4001C0.373626 21.3001 1.67358 26.7001 5.47358 30.6001C11.5736 37.0001 21.7735 37.2001 28.1735 31.1001C34.5735 25.0001 34.7735 14.8001 28.6735 8.40007C24.3735 4.00007 18.3734 2.50007 13.0734 3.80007Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Coffee, () => (
      <svg viewBox="0 0 30 30">
        <path d="M22.29,11.11h1.29a5.15,5.15,0,0,1,0,10.29H22.29" />
        <path d="M1.72,11.11H22.29V22.68a5.14,5.14,0,0,1-5.14,5.14H6.86a5.14,5.14,0,0,1-5.14-5.14Z" />
        <line x1="6.86" y1="2.11" x2="6.86" y2="5.97" />
        <line x1="12" y1="2.11" x2="12" y2="5.97" />
        <line x1="17.15" y1="2.11" x2="17.15" y2="5.97" />
      </svg>
    ))
    .with(Icons.Refresh, () => (
      <svg viewBox="0 0 22.131 27.263">
        <g transform="translate(-61.13 -4220.59)">
          <path
            d="M11.6,4060.553a10,10,0,1,1-5.572,5.676"
            transform="translate(56.896 167.033)"
          />
          <path
            d="M16.072,4066.285l-5.657-5.657,5.657-5.657"
            transform="translate(56.896 167.033)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Lightbulb, () => (
      <svg viewBox="0 0 24 24">
        <path d="M19.79,8.79A7.79,7.79,0,1,0,6,13.7a9,9,0,0,1,1.91,5.74V23h8.25V19.5a9.16,9.16,0,0,1,1.93-5.81A7.73,7.73,0,0,0,19.79,8.79Z" />
        <line x1="7.87" y1="16.69" x2="16.13" y2="16.69" />
        <line x1="7.87" y1="20.25" x2="16.13" y2="20.25" />
        <polyline points="10.17 16.68 10.17 10.27 13.83 10.27 13.83 16.68" />
      </svg>
    ))
    .with(Icons.Team, () => (
      <svg viewBox="0 0 28 19">
        <path d="M15.8708 18.0001V16.1543C15.8708 14.1143 14.2399 12.3657 12.1292 12.3657H4.74169C2.72693 12.3657 1 14.0172 1 16.1543V18.0001" />
        <path d="M8.19482 8.57707C10.2613 8.57707 11.9365 6.88085 11.9365 4.78848C11.9365 2.69611 10.2613 1 8.19482 1C6.12833 1 4.45312 2.69611 4.45312 4.78848C4.45312 6.88085 6.12833 8.57707 8.19482 8.57707Z" />
        <path d="M21.4346 17.9999V16.1542C21.4346 14.4056 20.2833 12.9484 18.6523 12.5598" />
        <path d="M26.9991 17.9999V16.1542C26.9991 14.4056 25.8478 12.9484 24.2168 12.5598" />
        <path d="M14.9121 1.19434C16.9269 1.68005 18.0782 3.72 17.5985 5.76C17.3106 7.12 16.2553 8.09148 14.9121 8.48005" />
        <path d="M20.4766 1.19434C22.4913 1.68005 23.6426 3.72 23.1629 5.76C22.8751 7.12 21.8197 8.09148 20.4766 8.48005" />
      </svg>
    ))
    .with(Icons.Playbook, () => (
      <svg viewBox="0 0 30 30">
        <path d="M4.45,25.06A3.4,3.4,0,0,1,7.8,21.71H25.72" />
        <path d="M7.67,1.85H25.59V28.41H7.67a3.4,3.4,0,0,1-3.35-3.35v-20A3.38,3.38,0,0,1,7.67,1.85Z" />
      </svg>
    ))
    .with(Icons.BigFive, () => (
      <svg viewBox="0 0 28 20">
        <line x1="1.72" y1="10.01" x2="28.72" y2="10.01" />
        <line x1="1.72" y1="15.47" x2="20.84" y2="15.47" />
        <line x1="1.72" y1="4.56" x2="9.59" y2="4.56" />
        <line x1="1.72" y1="26.37" x2="14.09" y2="26.37" />
        <line x1="1.72" y1="20.92" x2="6.22" y2="20.92" />
      </svg>
    ))
    .with(Icons.Enneagram, () => (
      <svg viewBox="0 0 30 30">
        <circle cx="5.64" cy="4.75" r="2.92" />
        <circle cx="15.32" cy="4.75" r="2.92" />
        <circle cx="25.35" cy="4.75" r="2.92" />
        <circle cx="5.64" cy="14.61" r="2.92" />
        <circle cx="15.32" cy="14.61" r="2.92" />
        <circle cx="25.35" cy="14.61" r="2.92" />
        <circle cx="5.64" cy="24.46" r="2.92" />
        <circle cx="15.32" cy="24.46" r="2.92" />
        <circle cx="25.35" cy="24.46" r="2.92" />
      </svg>
    ))
    .with(Icons.PersonalityTypes16, () => (
      <svg viewBox="0 0 30 30">
        <rect x="1.72" y="0.97" width="11" height="11" />
        <rect x="2.72" y="1.97" width="9" height="9" />
        <rect x="17.72" y="0.97" width="11" height="11" />
        <rect x="18.72" y="1.97" width="9" height="9" />
        <rect x="17.72" y="16.97" width="11" height="11" />
        <rect x="18.72" y="17.97" width="9" height="9" />
        <rect x="1.72" y="16.97" width="11" height="11" />
        <rect x="2.72" y="17.97" width="9" height="9" />
      </svg>
    ))
    .with(Icons.Scale, () => (
      <svg viewBox="0 0 30 30" className="default-stroke-1">
        <line x1="14.38" y1="7.47" x2="14.38" y2="26.52" />
        <line x1="8.48" y1="27.11" x2="20.29" y2="27.11" />
        <line x1="5.04" y1="9.78" x2="24.45" y2="3.83" />
        <line x1="19.85" y1="13.45" x2="24.53" y2="3.83" />
        <line x1="28.92" y1="13.45" x2="24.53" y2="3.83" />
        <path d="M19.85,13.45a4.54,4.54,0,0,0,9.07,0Z" />
        <line x1="0.72" y1="19.19" x2="5.04" y2="9.78" />
        <line x1="9.67" y1="19.19" x2="5.04" y2="9.78" />
        <path d="M.72,19.18a4.53,4.53,0,0,0,4.41,4.64h0a4.62,4.62,0,0,0,4.53-4.64Z" />
      </svg>
    ))
    .with(Icons.Search, () => (
      <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.54577 8.63452C6.46035 8.63452 8.01244 7.08239 8.01244 5.16781C8.01244 3.25322 6.46035 1.70117 4.54577 1.70117C2.63118 1.70117 1.0791 3.25322 1.0791 5.16781C1.0791 7.08239 2.63118 8.63452 4.54577 8.63452Z"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.84649 10.4347L7.14648 7.66797"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.SearchGray, () => (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6378 24.7034C18.3815 24.7034 23.0378 20.047 23.0378 14.3032C23.0378 8.55946 18.3815 3.90332 12.6378 3.90332C6.89403 3.90332 2.23779 8.55946 2.23779 14.3032C2.23779 20.047 6.89403 24.7034 12.6378 24.7034Z"
          stroke="#677F8B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.538 30.1028L20.438 21.8027"
          stroke="#677F8B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6378 24.7034C17.3815 24.7034 22.0378 20.047 22.0378 14.3032C22.0378 8.55946 17.3815 3.90332 11.6378 3.90332C5.89403 3.90332 1.23779 8.55946 1.23779 14.3032C1.23779 20.047 5.89403 24.7034 11.6378 24.7034Z"
          stroke="#80949E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.538 30.1028L19.438 21.8027"
          stroke="#80949E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Magic, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg2"
        width="32"
        height="27"
        fill="none"
        viewBox="0 0 32 27"
      >
        <path
          id="path1"
          d="m 8.9986364,0 c 0,0 0.098005,3.8784446 3.0041836,6.7846229 2.906158,2.9061583 5.994448,3.6473851 5.994448,3.6473851 0,0 -1.845379,-0.305293 -5.477367,3.328135 -3.6320078,3.633427 -3.5212646,6.670425 -3.5212646,6.670425 0,0 -0.019377,-3.854914 -3.5161257,-6.897031 C 1.9857422,10.491419 0,10.432008 0,10.432008 c 0,0 2.8648886,-0.299814 5.9990922,-3.4325778 C 9.1332759,3.8666463 8.9986364,0 8.9986364,0 Z"
          stroke="none"
          fill="currentColor"
        />
        <path
          id="path2"
          d="m 24.814232,12.434584 c 0,0 0.139779,3.452116 1.936106,5.248522 1.796527,1.796407 5.249402,1.937186 5.249402,1.937186 0,0 -2.920156,-0.225246 -5.165215,2.020693 -2.245058,2.246058 -2.020293,5.165015 -2.020293,5.165015 0,0 -0.168374,-3.42468 -2.329846,-5.304994 -2.161471,-1.880514 -4.855922,-1.880714 -4.855922,-1.880714 0,0 2.777638,-0.533839 4.714944,-2.470325 1.937305,-1.936505 2.470824,-4.715383 2.470824,-4.715383 z"
          stroke="none"
          fill="currentColor"
        />
      </svg>
    ))
    .with(Icons.MagnifyingGlass, () => (
      <svg viewBox="0 0 131.098 131.48">
        <g transform="translate(-119.493 -7091.036)">
          <g transform="translate(120.458 7092)">
            <circle cx="49.667" cy="49.667" r="49.667" />
            <path
              d="M73.881,59.117l34.883,34.169a11.05,11.05,0,0,1,0,15.345h0a11.05,11.05,0,0,1-15.345,0L57.887,73.823"
              transform="translate(17.307 17.822)"
            />
            <line
              x2="22.794"
              y2="22.794"
              transform="translate(91.863 93.064)"
            />
            <g transform="translate(12.261 13.639)">
              <path
                d="M11.1,68.267c.8-12.389,2.684-13.5,3.809-14.293A37.13,37.13,0,0,1,29.6,47.524a26,26,0,0,0,36.722.638l.638-.638a36.505,36.505,0,0,1,14.685,6.45c1.188.786,3.224,1.684,4.169,14.661"
                transform="translate(-11.102 0.181)"
              />
              <path
                d="M62.766,30.131c0,10.325-6.6,25.537-19.2,25.537S24.393,40.456,24.393,30.131a19.2,19.2,0,0,1,38.328,0"
                transform="translate(-6.515 -12.059)"
              />
            </g>
          </g>
          <g transform="translate(132.719 7105.639)">
            <path
              d="M73.881,59.117l34.883,34.169a11.05,11.05,0,0,1,0,15.345h0a11.05,11.05,0,0,1-15.345,0L57.887,73.823"
              transform="translate(5.045 4.183)"
            />
            <line
              x2="22.794"
              y2="22.794"
              transform="translate(79.602 79.425)"
            />
            <g
              transform="translate(0 0)"
              className="CE fill-disc"
              style={{ fillOpacity: 0.3 }}
            >
              <path
                d="M11.1,68.267c.8-12.389,2.684-13.5,3.809-14.293A37.13,37.13,0,0,1,29.6,47.524a26,26,0,0,0,36.722.638l.638-.638a36.505,36.505,0,0,1,14.685,6.45c1.188.786,4.169,14.661,4.169,14.661S65.585,85,48.959,85.3,11.1,68.267,11.1,68.267Z"
                transform="translate(-11.102 0.181)"
              />
              <path
                d="M62.766,30.131c0,10.325-6.6,25.537-19.2,25.537S24.393,40.456,24.393,30.131a19.2,19.2,0,0,1,38.328,0"
                transform="translate(-6.515 -12.059)"
              />
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Paint, () => (
      <svg viewBox="0 0 92.705 98.456">
        <g transform="translate(-724.397 -901.418)">
          <g transform="translate(-290 -3036)">
            <g transform="translate(1009.319 3437.789)">
              <path d="M76.356,574.661C87.657,566.2,94.521,552.1,93.7,536.33c-1.166-22.462-18.664-34.536-41.128-35.657q-.227-.012-.453-.021a7.552,7.552,0,0,0-7.073,10.814c1.285,2.672,8.612,12.778-.65,18.255-8.95,5.293-16.622-1.645-21.976-3.635-15.164-5.636-16.58,14.6-16.275,20.921.008.159.016.317.026.475C7.486,569.854,26.616,581.83,49,582.9a42.175,42.175,0,0,0,22.434-5.068m-8.077-55.253c-8.238,0-8.225-12.783,0-12.783S71.582,522.577,63.357,522.577Z" />
              <path
                className="CE Parented fill-disc-light"
                d="M89.692,594.415l-3.6,2.655L54.578,556.024l5.209-3.842Z"
              />
              <path
                className="CE Parented fill-disc-light"
                d="M59.787,552.182l-5.209,3.842-6.55-7.192a15.873,15.873,0,0,1-4.053-9.045l-.257-2.467a1.016,1.016,0,0,1,1.409-1.039l2.281.974a15.871,15.871,0,0,1,7.443,6.544Z"
              />
              <line
                y1="3.318"
                x2="4.499"
                transform="translate(59.359 558.464)"
              />
              <path
                className="CE Parented fill-disc-light"
                d="M70.713,550.27c-.911,2.462-.49,6.026,1.668,7.184,2.639,1.416,6.968-1.25,8.758-4.39,1.419-2.49,1.881-6.4,0-7.784C78.707,543.488,72.33,545.9,70.713,550.27Z"
              />
              <path
                className="CE Parented fill-disc-light"
                d="M74.348,527.554c-1.442,1.882.647,7.879,4.248,8.495,3.338.572,6.928-3.658,7.018-7.572a7.8,7.8,0,0,0-2.216-5.356c-2.077-2.213-5.469-3.31-6.279-2.4-.843.945,1.638,3.464.553,5.171C76.889,527.124,75.178,526.471,74.348,527.554Z"
              />
              <path
                className="CE Parented fill-disc-light"
                d="M30.891,562.542c-1.822,2.686-2.524,7.236,0,9.6,2.822,2.65,7.643.658,8.68.185,4.769-2.174,7.154-7.6,6.28-8.5-.808-.828-4.346,2.276-5.725,1.108-1.311-1.109,1.112-4.558-.186-5.91C38.559,557.593,33.34,558.93,30.891,562.542Z"
              />
              <path
                className="CE Parented fill-disc-light"
                d="M18.084,548.495c3.3,3.191,9.959,4.538,13.376,1.88,2.423-1.884,3.116-5.705,1.459-7.1-1.324-1.119-3.147.16-7.3-.209-5.7-.507-8-3.43-9.485-2.507C14.579,541.519,15.325,545.822,18.084,548.495Z"
              />
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Tada, () => (
      <svg viewBox="0 0 95.217 87.433">
        <g transform="translate(-1578.27 -1360.78)">
          <g transform="translate(69.619 380.926)">
            <line
              x2="10.031"
              y2="4.769"
              transform="translate(1515.522 1055.152)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1509.651,1064.286l55.848-15.331a1.427,1.427,0,0,0,.56-2.123l-22.588-31.592a1.425,1.425,0,0,0-2.19-.157Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1572.75,990.732l2.65,5.152,5.746.737-4.08,4.113,1.075,5.693-5.172-2.61-5.082,2.781.884-5.726-4.216-3.974,5.719-.929Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1578.325,1032.095l-.446,4.272,3.267,2.789-4.2.9-1.643,3.969-2.151-3.718-4.282-.336,2.872-3.194-1-4.177,3.926,1.744Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1548.5,1016.366a12.13,12.13,0,0,0,3.176-13.009,5.348,5.348,0,0,0-2.036-2.777,2.6,2.6,0,0,0-3.217.29c-.979,1.117-.317,3.055,1,3.734a4.666,4.666,0,0,0,4.265-.358c3.655-1.99,5.209-6.421,5.78-10.544.23-1.662.423-3.478-1.127-4.65-4.357-3.3-6.484,4.1-3.458,4.506,2.069.28,4.041-1.02,5.522-2.492,2.055-2.042,4.744-5.841,4.239-10.1"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1562.456,1029.238a5.813,5.813,0,0,1,1.463-5,15.194,15.194,0,0,1,8.222-4.222c4.012-.377,7.174,3.131,7.115,5.563-.091,3.719-11.28-2.274-.367-7.755a9,9,0,0,1,4.09-.795,7.82,7.82,0,0,1,4.149,1.014,4.126,4.126,0,0,1,2.039,3.585c-.058.932-.724,1.941-1.658,1.912a1.716,1.716,0,0,1-1.476-1.563,4.449,4.449,0,0,1,.487-2.261,17.323,17.323,0,0,1,6.245-7.94,10.323,10.323,0,0,1,9.772-.983"
            />
            <line
              y1="0.419"
              x2="3.416"
              transform="translate(1555.636 1012.897)"
            />
            <line
              y1="0.419"
              x2="3.416"
              transform="translate(1564.406 1012.066)"
            />
            <line
              x2="0.419"
              y2="3.416"
              transform="translate(1561.104 1006.598)"
            />
            <line
              x2="0.419"
              y2="3.416"
              transform="translate(1561.935 1015.368)"
            />
            <line
              x2="2.294"
              y2="2.565"
              transform="translate(1592.042 1024.592)"
            />
            <line
              x2="2.294"
              y2="2.565"
              transform="translate(1597.73 1031.319)"
            />
            <line
              x1="2.565"
              y2="2.294"
              transform="translate(1598.113 1025.248)"
            />
            <line
              x1="2.565"
              y2="2.294"
              transform="translate(1591.387 1030.935)"
            />
            <line
              x2="16.84"
              y2="8.876"
              transform="translate(1520.188 1047.895)"
            />
            <line
              x2="24.113"
              y2="11.463"
              transform="translate(1523.691 1042.445)"
            />
            <line
              x2="30.98"
              y2="14.727"
              transform="translate(1527.776 1036.091)"
            />
            <line
              x2="30.786"
              y2="14.635"
              transform="translate(1533.288 1029.386)"
            />
            <line
              x2="16.277"
              y2="7.738"
              transform="translate(1537.257 1021.948)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Idea, () => (
      <svg viewBox="0 0 75.497 100.259">
        <g transform="translate(-1588.767 -513.976)">
          <g transform="translate(69.619 380.926)">
            <path
              className="CE Parented fill-disc-light"
              d="M1566.349,166.26a13.59,13.59,0,1,0-23.219,9.585,13.752,13.752,0,0,1,3.936,9.724v1.516a2.884,2.884,0,0,0,2.884,2.883h5.62a2.884,2.884,0,0,0,2.884-2.883v-1.514a14.361,14.361,0,0,1,4.118-9.913A13.533,13.533,0,0,0,1566.349,166.26Z"
            />
            <line x2="11.388" transform="translate(1547.066 185.716)" />
            <line x2="11.388" transform="translate(1547.066 182.347)" />
            <path d="M1555.594,190.822a2.675,2.675,0,0,1-5.289,0" />
            <line
              x2="5.341"
              y2="0.032"
              transform="translate(1531.471 163.952)"
            />
            <line
              x2="5.341"
              y2="0.032"
              transform="translate(1569.476 163.952)"
            />
            <line
              y1="2.826"
              x2="4.532"
              transform="translate(1533.232 174.653)"
            />
            <line
              y1="2.826"
              x2="4.532"
              transform="translate(1566.757 150.498)"
            />
            <line
              y1="5.101"
              x2="1.582"
              transform="translate(1557.426 145.216)"
            />
            <line
              x1="2.009"
              y1="4.948"
              transform="translate(1546.512 145.292)"
            />
            <line
              x1="4.232"
              y1="3.258"
              transform="translate(1568.05 174.437)"
            />
            <line
              x1="2.874"
              y1="4.502"
              transform="translate(1562.797 181.333)"
            />
            <line
              y1="4.502"
              x2="2.874"
              transform="translate(1538.748 181.333)"
            />
            <line
              x1="4.625"
              y1="2.67"
              transform="translate(1534.327 150.848)"
            />
          </g>
          <path
            d="M1533.217,223.285l35.463,6.023c-.155-4.538-.358-16.165,1.487-18.827,2.08-3,5.66-3.1,8.82-3.189,2.772-.078,5.391-.152,6.756-2.02,1.661-2.274.747-6.022-.06-9.329-.811-3.328-1.578-6.472.523-7.932.971-.675,2.872.049,4.75-.713,5.483-2.227,1.285-8.117-1.442-12.292-2.818-4.313-4.59-11.874-4.912-14.806A27.235,27.235,0,0,0,1562.82,135c-10.778-2.523-22.928.435-28.442,4.75-17.849,13.968-18.94,35.49-3.071,60.6a1.064,1.064,0,0,1,.163.493Z"
            transform="translate(69.619 380.926)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Gavel, () => (
      <svg viewBox="0 0 102.756 96.629">
        <g transform="translate(-1576 -641.353)">
          <g transform="translate(69.619 380.926)">
            <path
              className="CE Parented fill-disc-light"
              d="M5.16,0H26.5A4.977,4.977,0,0,1,31.48,4.977V8.352a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5.16A5.16,5.16,0,0,1,5.16,0Z"
              transform="translate(1515.161 338.352)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M8.352,0H37.4a8.352,8.352,0,0,1,8.352,8.352v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A8.352,8.352,0,0,1,8.352,0Z"
              transform="translate(1507.381 346.704)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="40.554"
              height="26.06"
              transform="translate(1523.393 300.02) rotate(-45)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="8.519"
              height="35.9"
              rx="3.85"
              transform="translate(1548.59 267.865) rotate(-45)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="8.519"
              height="35.9"
              rx="3.85"
              transform="translate(1513.89 302.565) rotate(-45)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M0,0H14.306a0,0,0,0,1,0,0V56.813a7.153,7.153,0,0,1-7.153,7.153h0A7.153,7.153,0,0,1,0,56.813V0A0,0,0,0,1,0,0Z"
              transform="translate(1552.378 308.912) rotate(-45)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.BooksApple, () => (
      <svg viewBox="0 0 98.718 90.166">
        <g transform="translate(-1574 -758.6)">
          <g transform="translate(564 -3441)">
            <g transform="translate(-497.376 3821.092)">
              <path d="M1527.147,429.345h-5.576a3.842,3.842,0,0,1-3.842-3.842v-8.071a3.842,3.842,0,0,1,3.842-3.841h79.575" />
              <line x1="63.047" transform="translate(1538.099 429.345)" />
              <path d="M1598.073,429.345s-4.194-9.243,0-15.754" />
              <path d="M1587.512,445.1s-4.194-9.243,0-15.754" />
              <path d="M1599.668,460.853s-4.195-9.243,0-15.754" />
              <path
                className="CE Parented fill-disc-light"
                d="M1538.1,433.862l-5.476-3.129-5.476,3.129V421.2H1538.1Z"
              />
              <path
                className="CE Parented fill-disc-light"
                d="M1552.892,466.969l-5.476-3.129-5.476,3.129V454.31h10.952Z"
              />
              <line x2="48.788" transform="translate(1541.94 421.203)" />
              <line x2="53.694" transform="translate(1538.099 429.345)" />
              <path d="M1591.793,445.1h-79.575a3.842,3.842,0,0,1-3.842-3.842v-8.071a3.842,3.842,0,0,1,3.842-3.841h14.929" />
              <line x2="48.788" transform="translate(1532.586 438.556)" />
              <line x2="9.699" transform="translate(1515.518 438.556)" />
              <path d="M1541.332,460.853h-15.813a3.842,3.842,0,0,1-3.842-3.842V448.94a3.842,3.842,0,0,1,3.842-3.841h79.575" />
              <line x1="52.202" transform="translate(1552.892 460.853)" />
              <line x2="16.857" transform="translate(1574.271 454.31)" />
              <line x2="37.514" transform="translate(1528.819 454.31)" />
              <path
                className="CE Parented fill-disc-light"
                d="M1555.191,388.238s-7.575-4.654-12.338,4.2c-1.081,2.009-1.441,4.808.264,8.619,1.356,3.029,2.679,6.275,6.351,9.432,1.147.986,3.613,2.355,6.05-.07,0,0,2.847,2.81,5.138.07a40.116,40.116,0,0,0,6.35-9.432c1.706-3.811,1.3-6.584.265-8.619C1562.45,382.921,1555.191,388.238,1555.191,388.238Z"
              />
              <path d="M1550.989,379.908a11.782,11.782,0,0,1,4.2,8.33" />
              <path
                className="CE Parented fill-disc"
                d="M1559.747,385.38c-1.263,1.96-4.315,2.242-4.315,2.242s-1.005-2.9.257-4.856,4.316-2.242,4.316-2.242S1561.01,383.42,1559.747,385.38Z"
              />
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Brain, () => (
      <svg viewBox="0 0 98.722 84.159">
        <g transform="translate(-1578.15 -888.489)">
          <g
            transform="translate(69.619 380.926)"
            className="CE Parented fill-disc-light"
          >
            <path d="M1604.253,540.172a11.966,11.966,0,0,0-7.048-10.892A14.627,14.627,0,0,0,1584.07,517a11.05,11.05,0,0,0-9.931-6.194h0c-.534,0-1.068.107-1.6.107h-.107a10.081,10.081,0,0,0-1.709.427,12.246,12.246,0,0,0-7.582-2.776,10.959,10.959,0,0,0-6.3,1.922,15.447,15.447,0,0,0-6.3-1.282,15.074,15.074,0,0,0-11.426,5.126,12.352,12.352,0,0,0-2.777-.32,10.923,10.923,0,0,0-9.4,5.232h-.961c-.214,0-.428.107-.641.107s-.32.107-.534.107a1.517,1.517,0,0,0-.641.214c-.213,0-.32.106-.534.106-.213.107-.427.107-.534.214-.213.107-.427.107-.534.214s-.32.106-.427.213l-.641.321h0a12.953,12.953,0,0,0-6.62,10.465v.107a17.3,17.3,0,0,0,11.96,29.794,28.089,28.089,0,0,0,2.99-.214,17.058,17.058,0,0,0,4.058.748c2.777,6.834,8.33,10.465,15.912,10.465,7.261,0,16.018,7.369,16.018,16.019v1.6h12.174l-1.388-9.931a17.4,17.4,0,0,0,18.581-13.028,21.329,21.329,0,0,0,.534-4.379,12.924,12.924,0,0,0,8.543-12.067,11.927,11.927,0,0,0-1.068-5.126A13.641,13.641,0,0,0,1604.253,540.172Z" />
            <path d="M1539.817,546.677s-9,5.692-5.938,14.96" />
            <path d="M1521.883,550.535s-2.436-4.549,1.107-6.481c0,0-9.6-4.739-8.119-12.745" />
            <path d="M1525.647,531.309a12.951,12.951,0,0,0,14.17-2.472" />
            <path d="M1538.175,522.356s-.234,6.952,7.737,8.273" />
            <path d="M1555.012,560.176s9.712,10.91,23.745,1.374" />
            <path d="M1552.661,538.716s11.808,13.034,28.6,1.406" />
            <path d="M1563.758,550.535s1.28-5.025-4.314-7.259" />
            <path d="M1563.455,529.282s-.7-6.261-8.022-6.4" />
            <path d="M1547.084,556.653s3.767-9.166-3.1-16.986" />
            <path d="M1589.728,536.926s-.688-11.752-14.844-11.217" />
            <path d="M1595.71,562.384s-1.046-11.986-15.2-11.451" />
            <path d="M1563.884,519.382s1.266-6.587,6.837-8.043" />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Link, () => (
      <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9771 4.07617H3.80385C2.24615 4.07617 1 5.32233 1 6.88002V26.5069C1 28.0646 2.24615 29.3108 3.80385 29.3108H23.3269C24.8846 29.3108 26.1308 28.0646 26.1308 26.5069V18.3336"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2846 11.6762L26.2846 3.89257L18.501 3.89258"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4229 18.6934L25.8445 4.18658"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Lion, () => (
      <svg viewBox="0 0 85.615 101.534">
        <g transform="translate(-1584.034 -994.764)">
          <g transform="translate(69.619 380.926)">
            <path d="M1593.249,649.89c2.634,6.482,5.627,16.618,5.775,24.166.108,5.479-.831,10.608-1.826,16.039-.167.908-.334,1.829-.5,2.756q-.415-.744-.833-1.485-.765-1.365-1.526-2.73a1.805,1.805,0,0,0-3.289.3c-.023.07-2.339,6.982-3.375,9.123-.682,1.433-2.573,4.46-4,6.683l-3.522-7.207a1.8,1.8,0,0,0-3.2-.089l-6.166,11.006-3.9-3.557a1.806,1.806,0,0,0-2.635.219l-7.276,9.254-6.817-9.212a1.8,1.8,0,0,0-2.664-.262l-3.915,3.561-6.168-11.009a1.838,1.838,0,0,0-1.625-.923,1.806,1.806,0,0,0-1.571,1.012l-3.284,6.721c-.451-.6-.969-1.236-1.534-1.923-.346-.417-.775-.938-.879-1.1a53.143,53.143,0,0,1-5.185-12.179,1.8,1.8,0,0,0-3.3-.32l-2.5,4.574c-.147-.417-.3-.851-.329-.992-.477-2.087-.864-4.222-1.15-6.346a71.827,71.827,0,0,1-.589-12.415c.307-7.76,3.184-18.019,5.659-24.131" />
            <path d="M1525.735,653.81a1.808,1.808,0,0,0-1.059-2.323,8.675,8.675,0,0,1-4.7-4.054,6.64,6.64,0,0,1-.426-5.456c1.289-3.443,5.454-5.209,9.485-4.016.948.282-8.377-6.907-8.377-6.907,1.494-.6,3.159-1.239,4.037-1.52,1.994-.658,8.245-2.044,8.306-2.059a1.8,1.8,0,0,0,.969-2.948l-4.248-4.865a32.64,32.64,0,0,1,4.44.145c2.947.291,6.041,1.841,7.889,2.766a12.065,12.065,0,0,0,2.039.9,1.942,1.942,0,0,0,1.936-.577c.592-.655,1.2-1.329-1.089-8.056a13.886,13.886,0,0,1,6.974,2.933,25.346,25.346,0,0,1,2.031,1.933,4.43,4.43,0,0,0,3.191,1.366h0a4.43,4.43,0,0,0,3.193-1.368,24.483,24.483,0,0,1,2.03-1.931,13.886,13.886,0,0,1,6.975-2.933c-2.289,6.727-1.681,7.4-1.09,8.056a1.953,1.953,0,0,0,1.949.573,12,12,0,0,0,2.026-.894c1.848-.925,4.942-2.475,7.891-2.766a32.505,32.505,0,0,1,4.439-.145l-4.249,4.865a1.8,1.8,0,0,0,.97,2.948c.062.015,6.31,1.4,8.321,2.062.871.279,2.531.923,4.023,1.517,0,0-9.113,7.19-8.168,6.907a8.832,8.832,0,0,1,3.832-.272,1.791,1.791,0,0,0,.4.074,6.911,6.911,0,0,1,5.254,4.214,6.635,6.635,0,0,1-.426,5.456,8.677,8.677,0,0,1-4.7,4.054,1.8,1.8,0,0,0-1.058,2.322" />
            <path d="M1575.547,678.441" />
            <path d="M1557.369,645.949" />
            <path d="M1555.531,644.836s-14.963-10.512-19.586,6.248c-1.409,5.107-5.374,18.608,7.717,26.694" />
            <path d="M1556.861,644.836s14.963-10.512,19.586,6.248c1.409,5.107,5.164,18.657-7.95,27.077" />
            <line y1="7.244" transform="translate(1556.22 645.362)" />
            <line y2="5.408" transform="translate(1556.22 656.59)" />
            <path
              className="CE Parented fill-disc"
              d="M1526.584,647.627a3.076,3.076,0,1,1,3.093-5.245"
            />
            <path
              className="CE Parented fill-disc"
              d="M1588.389,647.627a3.076,3.076,0,1,0-3.093-5.245"
            />
            <path
              className="CE Parented fill-disc"
              d="M1559.633,680.532l-4.33,3.68-4.33-3.68Z"
            />
            <path
              className="CE Parented fill-disc"
              d="M1550.232,656.6s-3.1,1.223-5.312-.055-2.705-4.574-2.705-4.574,3.1-1.222,5.313.056S1550.232,656.6,1550.232,656.6Z"
            />
            <path
              className="CE Parented fill-disc"
              d="M1563.011,656.6s3.1,1.223,5.313-.055,2.7-4.574,2.7-4.574-3.1-1.222-5.313.056S1563.011,656.6,1563.011,656.6Z"
            />
            <path d="M1550.232,656.6s2.727,10.281-1.105,15.844c-3.051,4.429-11.545,12.3-3.627,17.023,5.7,3.395,10-3.08,10.047-5.256" />
            <path d="M1563.011,656.6s-4.029,10.281-.2,15.844c3.051,4.429,11.544,12.3,3.627,17.023-5.7,3.395-10-3.08-10.047-5.256" />
            <path d="M1546.45,690.434l-.956.828a.963.963,0,0,0-.1,1.412c1.488,1.663,5.415,5.456,10.272,5.456,5.005,0,9.372-3.54,11.055-5.1a.961.961,0,0,0,.034-1.429l-1.543-1.544" />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Mountain, () => (
      <svg viewBox="0 0 101.359 88.421">
        <g transform="translate(-1577.511 -1123.972)">
          <path
            className="CE Parented fill-disc-light"
            d="M1578.012,828.678l-32.042-64.743-37.078,64.743Z"
            transform="translate(69.619 380.926)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1565.933,803.5l12.278-21.439a2.247,2.247,0,0,1,3.958.118l23.593,46.5h-27.75"
            transform="translate(69.619 380.926)"
          />
          <path
            d="M1535.018,783.059l5.474,4.152a3.644,3.644,0,0,0,4.425-.014l3.217-2.471a3.642,3.642,0,0,1,4.375-.052l5.765,4.219"
            transform="translate(69.619 380.926)"
          />
          <path
            d="M1571.267,794.184l3.143,1.372a5.126,5.126,0,0,0,4.41-.148l7.876-4.085"
            transform="translate(69.619 380.926)"
          />
          <line y2="19.89" transform="translate(1615.382 1124.972)" />
          <rect
            className="CE Parented fill-disc"
            width="12.961"
            height="8.251"
            transform="translate(1615.382 1124.972)"
          />
        </g>
      </svg>
    ))
    .with(Icons.TwoFingersUp, () => (
      <svg viewBox="0 -30 59.789 165">
        <g
          transform="translate(-1589.47 -1235.25)"
          className="CE Parented fill-disc-light"
        >
          <path
            d="M1530.987,902.975V865.546a5.759,5.759,0,0,1,5.76-5.759h0a5.759,5.759,0,0,1,5.759,5.759v39.115"
            transform="translate(69.619 380.926)"
          />
          <path
            d="M1542.506,900.2V861.084a5.76,5.76,0,0,1,5.76-5.76h0a5.76,5.76,0,0,1,5.76,5.76V900.2"
            transform="translate(69.619 380.926)"
          />
          <rect
            width="11.519"
            height="32.341"
            rx="5.203"
            transform="translate(1623.644 1263.15)"
          />
          <rect
            width="11.519"
            height="32.341"
            rx="5.203"
            transform="translate(1635.731 1270.295)"
          />
          <path
            d="M1577.632,915.951s-.178,21.443-3.784,28.109c-6.649,12.289-44.26,12.862-49.528-1.542-4.789-13.1-3.327-44.464-3.273-56.563a2.833,2.833,0,0,1,1.5-2.569c1.972-.955,3.843.878,5.312,3.21a20.771,20.771,0,0,1,3.076,10.957l.13,20.321"
            transform="translate(69.619 380.926)"
          />
        </g>
      </svg>
    ))
    .with(Icons.HeartArrow, () => (
      <svg viewBox="0 0 98.734 95.283">
        <g transform="translate(-1577.511 -1479.312)">
          <g transform="translate(69.619 380.926)">
            <path
              className="CE Parented fill-disc-light"
              d="M1571.677,1115.937c-6.208,0-11.63,4.856-14.565,9.846-2.935-4.99-8.357-9.846-14.565-9.846a16.889,16.889,0,0,0-16.889,16.889c0,20.441,31.454,41.708,31.454,41.708s31.454-21.022,31.454-41.708A16.888,16.888,0,0,0,1571.677,1115.937Z"
            />
            <path
              className="CE Parented fill-disc"
              d="M1540.721,1160.937l-7.343,7.669.1,9.051-14.688,15.013-1.2-8.76-8.693-.923,13.837-14.142,10.65-.239"
            />
            <path
              className="CE Parented fill-disc"
              d="M1583.635,1120.633l9.321-9.741-6.114-5.683,18.784-5.822-5.424,18.189-7.246-6.684"
            />
          </g>
          <path
            d="M1571.677,1115.937c-6.208,0-11.63,4.856-14.565,9.846-2.935-4.99-8.357-9.846-14.565-9.846a16.889,16.889,0,0,0-16.889,16.889c0,20.441,31.454,41.708,31.454,41.708s31.454-21.022,31.454-41.708A16.888,16.888,0,0,0,1571.677,1115.937Z"
            transform="translate(69.619 380.926)"
          />
        </g>
      </svg>
    ))
    .with(Icons.BackArrow, () => (
      <svg width="30" height="23" fill="none" viewBox="0 0 30 23">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 13.458905,1 0.9999424,11.227368 13.458905,21.45468"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 28.27273,11.420433 H 2.1793621"
        />
      </svg>
    ))
    .with(Icons.HandHeart, () => (
      <svg viewBox="0 0 133.432 116.722">
        <g transform="translate(-1232 -7192)">
          <g transform="translate(1233 7193)">
            <rect
              width="16.306"
              height="49.325"
              transform="translate(16.306 113.037) rotate(180)"
            />
            <path
              d="M2288.162,2196.3s18.573-14.826,44.157-.444l20.462-.3s5.157-.465,5.157,4.51c0,5.2-3.994,4.987-3.994,4.987l-32.687,1.152"
              transform="translate(-2271.856 -2121.771)"
            />
            <path
              d="M2288.162,2228.627s18.769-3.641,35.112.462,23.9,1.081,23.9,1.081l53.928-34.56s4.429-3.345.615-8.155c-3.837-4.84-8.391-1.709-8.391-1.709l-34.855,17.464"
              transform="translate(-2271.856 -2123.911)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M2350.948,2142.6c-6.444,0-12.073,5.042-15.122,10.223-3.047-5.181-8.676-10.223-15.122-10.223a17.537,17.537,0,0,0-17.535,17.536c0,21.223,32.657,43.3,32.657,43.3s32.659-21.826,32.659-43.3A17.537,17.537,0,0,0,2350.948,2142.6Z"
              transform="translate(-2265.203 -2142.599)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.HandTalk, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
        <path
          d="M2 21.1348V27.2349"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 20.9348C2 20.9348 6.2 16.5347 12 20.8347L16.5996 20.7346C16.5996 20.7346 17.7998 20.6349 17.7998 22.0349C17.7998 23.5349 16.8999 24.5349 16.8999 24.5349L11.5 24.8347"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 28.0355C2 28.0355 6.1999 27.2351 9.8999 28.1351C13.5999 29.0351 14.6 28.8351 16 28.6351C19.5 28.0351 27.5 21.9354 27.5 21.9354C27.5 21.9354 28.4996 20.2351 27.5996 19.1351C26.6996 18.1351 25.6997 18.7352 25.6997 18.7352L17.7998 22.5355"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0996 13.1348H20.1997L17.3999 17.2349L14.5 13.1348H7C5.7 13.1348 4.59961 12.2352 4.59961 11.0352V4.63477C4.59961 3.53477 5.7 2.53516 7 2.53516H21C22.3 2.53516 23.3999 3.43477 23.3999 4.63477V11.0352C23.3999 12.2352 22.3996 13.1348 21.0996 13.1348Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.TwoSpeechBubbles, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
        <path
          d="M17 11.1352C17 11.9352 16.4 12.5352 15.6 12.5352H6.89999L4 15.4352V3.93517C4 3.13517 4.59999 2.53516 5.39999 2.53516H15.5C16.3 2.53516 16.9 3.13517 16.9 3.93517V11.1352H17Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7998 24.5356C13.7998 25.3356 14.3998 25.9355 15.1998 25.9355H23.8998L26.7998 28.8356V17.3356C26.7998 16.5356 26.1998 15.9355 25.3998 15.9355H15.2998C14.4998 15.9355 13.8998 16.5356 13.8998 17.3356V24.5356H13.7998Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Conversation, () => (
      <svg viewBox="0 0 78.628 97.115">
        <g transform="translate(-740.715 -1740.715)">
          <circle
            cx="11.682"
            cy="11.682"
            r="11.682"
            transform="translate(751.825 1777.178)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M49.982,1416.281H16.393a4.954,4.954,0,0,1-4.774-6.313,22.476,22.476,0,0,1,43.137,0A4.954,4.954,0,0,1,49.982,1416.281Z"
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          />
          <circle
            cx="11.682"
            cy="11.682"
            r="11.682"
            transform="translate(784.526 1785.939)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M53.327,1406.41a22.475,22.475,0,0,1,34.519,13.851c.036.164.07.33.1.5a3.614,3.614,0,0,1-3.555,4.284H47.376a3.59,3.59,0,0,1-3.562-4.206l.008-.041a22.276,22.276,0,0,1,1.371-4.514"
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M25.64,1354.1h3.877l3.518,7.405,3.775-7.405H53.723a4.755,4.755,0,0,0,4.755-4.755v-14.662a4.755,4.755,0,0,0-4.755-4.755H25.64a4.755,4.755,0,0,0-4.756,4.755v14.662A4.755,4.755,0,0,0,25.64,1354.1Z"
            transform="translate(730.319 411.789)"
          />
          <path
            d="M58.478,1345.715h12.91a3.591,3.591,0,0,1,3.591,3.591v11.072a3.591,3.591,0,0,1-3.591,3.591H68.46L65.8,1369.56l-2.851-5.591H50.18a3.591,3.591,0,0,1-3.591-3.591v-6.28"
            transform="translate(730.319 411.789)"
          />
          <line x2="24.133" transform="translate(758.062 1749.773)" />
          <line x2="24.133" transform="translate(758.062 1757.504)" />
          <circle
            cx="1.816"
            cy="1.816"
            r="1.816"
            transform="translate(783.006 1764.857)"
          />
          <circle
            cx="1.816"
            cy="1.816"
            r="1.816"
            transform="translate(788.798 1764.857)"
          />
          <circle
            cx="1.816"
            cy="1.816"
            r="1.816"
            transform="translate(794.589 1764.857)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Copy, () => (
      <svg
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.7692 8H10.2308C8.44646 8 7 9.44646 7 11.2308V25.7692C7 27.5535 8.44646 29 10.2308 29H24.7692C26.5535 29 28 27.5535 28 25.7692V11.2308C28 9.44646 26.5535 8 24.7692 8Z"
          stroke="#1E749F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.84615 23H4.23077C3.37392 23 2.55216 22.6596 1.94627 22.0537C1.34038 21.4478 1 20.6261 1 19.7692V5.23077C1 4.37392 1.34038 3.55216 1.94627 2.94627C2.55216 2.34038 3.37392 2 4.23077 2H18.7692C19.6261 2 20.4478 2.34038 21.0537 2.94627C21.6596 3.55216 22 4.37392 22 5.23077V6.84615"
          stroke="#1E749F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Group, () => (
      <svg viewBox="0 0 97.536 58.233">
        <g transform="translate(-1833.052 -1623.411)">
          <path
            d="M1718.31,1300.2h-30.683a4.525,4.525,0,0,1-4.361-5.766c2.492-8.528,7.6-14.757,19.7-14.757,10.549,0,17.211,6.229,19.7,14.757A4.524,4.524,0,0,1,1718.31,1300.2Z"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1713.517,1260.3c0,5.442-3.481,13.461-10.119,13.461s-10.107-8.019-10.107-13.461a9.757,9.757,0,0,1,10.113-9.525c5.13.016,10.09,3.238,10.09,9.525"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1719.626,1287.929a22.164,22.164,0,0,1,10.942-2.447c10.548,0,17.21,6.23,19.7,14.757a4.526,4.526,0,0,1-4.361,5.767h-30.683a4.525,4.525,0,0,1-4.36-5.767"
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M1741.116,1266.106c0,5.442-3.481,13.461-10.118,13.461s-10.108-8.019-10.108-13.461a9.757,9.757,0,0,1,10.113-9.525c5.131.016,10.09,3.238,10.09,9.525"
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M1694.545,1300.239a4.525,4.525,0,0,1-4.36,5.767H1659.5a4.526,4.526,0,0,1-4.361-5.767c2.492-8.527,7.6-14.757,19.7-14.757a21.155,21.155,0,0,1,10.213,2.421"
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M1685.392,1266.106c0,5.442-3.481,13.461-10.119,13.461s-10.107-8.019-10.107-13.461a9.757,9.757,0,0,1,10.113-9.525c5.13.016,10.09,3.238,10.09,9.525"
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc-light"
          />
        </g>
      </svg>
    ))
    .with(Icons.PlaybookScale, () => (
      <svg viewBox="0 0 98.009 73.339">
        <g transform="translate(-1833 -1742.928)">
          <path
            d="M1698.666,1374.845a4.555,4.555,0,0,1,9.11,0"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1736.8,1375.791l-14.1,28.7h28.2l-14.1-28.7h-67.81l-14.1,28.7h28.2l-14.1-28.7"
            transform="translate(179.114 373.638)"
          />
          <line x2="54.735" transform="translate(1854.637 1815.267)" />
          <path
            className="CE Parented fill-disc-light"
            d="M1750.894,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1683.084,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <rect
            width="9.11"
            height="65.516"
            className="CE Parented fill-disc-light"
            transform="translate(1877.78 1749.751)"
          />
          <path
            d="M1750.894,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1683.084,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <rect
            width="9.11"
            height="65.516"
            transform="translate(1877.78 1749.751)"
          />
        </g>
      </svg>
    ))
    .with(Icons.GroupThink, () => (
      <svg viewBox="0 0 87.061 100.47">
        <g transform="translate(-1840.47 -382.032)">
          <path
            className="CE Parented fill-disc-light"
            d="M1713.711,33.125a8.523,8.523,0,1,1-8.523-8.521A8.523,8.523,0,0,1,1713.711,33.125Z"
            transform="translate(179.114 373.638)"
          />
          <circle
            className="CE Parented fill-disc-light"
            cx="6.943"
            cy="6.943"
            r="6.943"
            transform="translate(1845.986 396.904)"
          />
          <circle
            className="CE Parented fill-disc-light"
            cx="6.943"
            cy="6.943"
            r="6.943"
            transform="translate(1908.129 396.904)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1679.167,40.8h-11.581a5.262,5.262,0,0,0-5.26,5.39l.278,11.389a4.746,4.746,0,0,0,4.508,3.75l1.069,25.369h10.566l1.832-25.373a4.737,4.737,0,0,0,4.447-3.746l.278-11.389a5.262,5.262,0,0,0-5.26-5.39Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1741.31,40.8h-11.581a5.261,5.261,0,0,0-5.26,5.39l.277,11.389a4.749,4.749,0,0,0,4.509,3.75l1.069,25.369h10.566l1.832-25.373a4.737,4.737,0,0,0,4.447-3.746l.278-11.389a5.262,5.262,0,0,0-5.26-5.39Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1712.347,45.5h-15.491a7.037,7.037,0,0,0-7.036,7.209l.371,15.236c.356,2.673,2.9,4.719,6.03,5.016l1.431,33.934h14.133l2.451-33.94c3.1-.325,5.594-2.36,5.948-5.01l.372-15.236a7.038,7.038,0,0,0-7.036-7.209Z"
            transform="translate(179.114 373.638)"
          />
          <line x2="9.422" y2="6.61" transform="translate(1860.442 393.578)" />
          <line x1="9.422" y2="6.61" transform="translate(1896.999 393.578)" />
          <line y1="7.628" transform="translate(1883.97 383)" />
        </g>
      </svg>
    ))
    .with(Icons.Hamburger, () => (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7H28"
          stroke="#1E749F"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1 14.7998H28"
          stroke="#1E749F"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1 22.5996H28"
          stroke="#1E749F"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
    ))
    .with(Icons.Branch, () => (
      <svg viewBox="0 0 96.348 87.823">
        <g transform="translate(-1834.83 -511.56)">
          <g transform="translate(179.114 373.638)">
            <path d="M1661.023,224.458c2.7-7.2,7.839-17.6,18.078-25.241,15.98-11.93,31.212-7.331,42.977-19.442,4.443-4.573,9.655-12.829,10.233-28.651" />
            <path
              className="CE Parented fill-disc-light"
              d="M1727.7,156.456c3.006,4.9,1,10.952,1,10.952s-6.441-1.185-9.307-5.859-.979-10.919-.979-10.919S1724.974,152.008,1727.7,156.456Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1742.843,159.7c-5.027,2.791-10.985.526-10.985.526s1.462-6.384,6.255-9.045,10.951-.507,10.951-.507S1747.405,157.17,1742.843,159.7Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1735.439,145.259c-.043,3.689-3.2,6.289-3.2,6.289s-3.093-2.844-3.053-6.362,3.2-6.264,3.2-6.264S1735.477,141.911,1735.439,145.259Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1718.073,172.033c1.643,6.494-2.685,12.529-2.685,12.529s-6.754-3.552-8.321-9.744c-1.571-6.209,2.7-12.484,2.7-12.484S1716.581,166.14,1718.073,172.033Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1699.823,180.615c-1.722,8.191-9.892,12.586-9.892,12.586s-5.63-7.694-3.988-15.5c1.646-7.831,9.881-12.531,9.881-12.531S1701.386,173.182,1699.823,180.615Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1673.883,194.338c.463,10.12-7.78,17.745-7.78,17.745s-8.928-7.311-9.369-16.96c-.443-9.675,7.783-17.676,7.783-17.676S1673.462,185.155,1673.883,194.338Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1685.766,217.9c-9.749-2.754-14.385-12.982-14.385-12.982s9.755-6.164,19.05-3.539c9.321,2.632,14.318,12.962,14.318,12.962S1694.613,220.393,1685.766,217.9Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1708.09,204.509c-7.382-3.945-9.316-13.019-9.316-13.019s8.962-3.252,16,.509c7.058,3.772,9.265,12.993,9.265,12.993S1714.789,208.089,1708.09,204.509Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1733.142,186.729c-6.684-.447-11.083-6.429-11.083-6.429s5.466-5.324,11.84-4.9c6.39.426,11.037,6.425,11.037,6.425S1739.208,187.133,1733.142,186.729Z"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M1664.531,188.935"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Warning, () => (
      <svg viewBox="0 0 95.159 77.462">
        <g transform="translate(-1834.726 -757.407)">
          <path
            d="M1749.771,460.23h-93.159l46.579-75.462Z"
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc-light"
          />
          <path
            d="M1706.6,441.264h-5.1l-3-35.363h11.1Z"
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc"
          />
          <circle
            cx="3.669"
            cy="3.669"
            r="3.669"
            transform="translate(1879.502 820.068)"
            className="CE Parented fill-disc"
          />
        </g>
      </svg>
    ))
    .with(Icons.Whistle, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
        <path
          d="M23.6375 22.1015C23.0881 26.6923 19.7914 30.5357 14.9562 30.5357C10.1211 30.5357 6.2749 26.7991 6.2749 22.1015C6.2749 18.4717 8.80238 15.8026 11.8793 14.2012C13.6375 13.2403 27.154 6.94141 27.154 6.94141C28.9123 8.75635 31.0002 12.1727 31.0002 12.1727C31.0002 12.1727 26.3848 15.0553 21.9892 17.8311C22.0991 17.8311 23.9672 19.646 23.6375 22.1015Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1209 29.0405C8.8022 28.6135 7.37363 27.8661 6.38462 27.4391C3.30769 26.0512 1 23.1686 1 19.6455C1 16.0156 3.52747 13.3466 6.6044 11.7451C8.36264 10.7843 21.8791 4.48535 21.8791 4.48535C24.8462 5.76649 27.2637 6.94087 27.2637 6.94087"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.978 8.64844L18.4725 10.8904"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1867 0.535156L13.4175 5.12591"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.4834 1.60254L9.6812 6.19329"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.76904 6.08691L6.27454 8.43567"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Diamond, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.186 94.15"
      >
        <g transform="translate(-1834.209 -993.179)">
          <g
            transform="translate(179.114 373.638)"
            className="CE Parented fill-disc-light"
          >
            <path d="M1717.145,647.882h-61.051l47.093,63.808,47.093-63.808Z" />
            <path d="M1717.145,647.882H1687.5l15.693,63.808,15.693-63.808Z" />
            <path d="M1717.145,647.882H1687.5l15.693,63.808,15.693-63.808Z" />
            <path d="M1718.88,647.882l-15.693,63.808,47.093-63.808Z" />
            <path d="M1687.5,647.882l15.693,63.808-47.093-63.808Z" />
            <path d="M1671.8,620.541l-15.708,27.342h31.4Z" />
            <path d="M1703.188,620.541,1687.5,647.882h31.385Z" />
            <path d="M1734.573,620.541l-15.693,27.342h31.4Z" />
            <line x2="62.778" transform="translate(1671.803 620.541)" />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.HeartFlower, () => (
      <svg viewBox="0 0 76.542 98.297">
        <g transform="translate(-1846.811 -1108.623)">
          <path
            className="CE Parented fill-disc-light"
            d="M1704.548,735.985c-3.383,0-6.339,2.647-7.939,5.367-1.6-2.72-4.556-5.367-7.939-5.367a9.207,9.207,0,0,0-9.207,9.207c0,11.143,17.146,22.736,17.146,22.736s17.146-11.46,17.146-22.736A9.207,9.207,0,0,0,1704.548,735.985Z"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1669.056,831.9c4.143-2.3,16.522-7.522,36.9-7.522s34.048,5.739,36.9,7.522"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1705.847,784.8s-1.505,2.854-.592,9.283c.6,4.22,3.744,9.98,3.744,17.484a26.235,26.235,0,0,1-3.152,12.813"
            transform="translate(179.114 373.638)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1725.236,779.741c-7.058,7.058-19.318,4.965-19.318,4.965s-3.339-11.015,4.965-19.318c6.915-6.916,20.736-6.383,20.736-6.383S1732.294,772.684,1725.236,779.741Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1694.885,784.887c8.966,4.387,10.962,16.664,10.962,16.664s-9.336,6.731-19.884,1.57c-8.785-4.3-12.764-17.545-12.764-17.545S1685.92,780.5,1694.885,784.887Z"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1685.123,791.26s11.808,9.33,20.724,10.291"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1705.918,784.706s9.119-12.932,16.529-17.048"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1705.255,794.085s-8.076-12.785-8.646-26.157"
            transform="translate(179.114 373.638)"
          />
        </g>
      </svg>
    ))
    .with(Icons.GlassHalfFull, () => (
      <svg viewBox="0 0 55.7 96.63">
        <g transform="translate(-1853.149 -1228.837)">
          <ellipse
            cx="19.45"
            cy="3.156"
            rx="19.45"
            ry="3.156"
            transform="translate(1861.903 1272.23)"
          />
          <ellipse
            cx="26.85"
            cy="3.156"
            rx="26.85"
            ry="3.156"
            transform="translate(1854.149 1229.837)"
          />
          <path
            d="M1675.035,859.355l4.7,88.318c0,1.743,9.918,3.156,22.152,3.156s22.153-1.413,22.153-3.156l4.7-88.318"
            transform="translate(179.114 373.638)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1682.789,901.748l2.86,41.5c0,.819,7.294,1.483,16.292,1.483s16.292-.664,16.292-1.483l3.455-41.5"
            transform="translate(179.114 373.638)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Smile, () => (
      <svg viewBox="0 0 97.982 97.983">
        <g transform="translate(-1835.639 -1354.978)">
          <circle
            cx="46.991"
            cy="46.991"
            r="46.991"
            transform="translate(1836.639 1355.978)"
          />
          <circle
            cx="46.991"
            cy="46.991"
            r="46.991"
            transform="translate(1836.639 1355.978)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M1729.286,1045.486a29.066,29.066,0,1,1-54.159-21.123Z"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1693.157,1001.943l8.052,10.466-13.79,1.656"
            transform="translate(179.114 373.638)"
          />
          <path
            d="M1729.286,1017.106l-13.189.647,7.673,11.577"
            transform="translate(179.114 373.638)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Flower, () => (
      <svg viewBox="0 0 99.727 70.016">
        <g transform="translate(-1833.641 -1494.383)">
          <g transform="translate(179.114 373.638)">
            <path d="M1690.212,1136.387a77.629,77.629,0,0,1,12.678-14.642,62.957,62.957,0,0,1,12.4,14.642" />
            <path
              className="CE Parented fill-disc-light"
              d="M1739.382,1178.889c-12.978,12.978-36.332,10.665-36.332,10.665s-2.313-23.354,10.666-36.332,36.332-10.666,36.332-10.666S1752.361,1165.91,1739.382,1178.889Z"
            />
            <path
              d="M1703.268,1174.289c-.271-5.991.443-22.839,4.179-28.8,7.956-12.686,26.5-14.812,26.5-14.812s2.386,4.312,1.944,13.118"
              transform="translate(-0.326)"
            />
            <path d="M1702.538,1169.584c.268-5.719-.159-19.077-3.858-24.766-7.877-12.11-26.237-14.14-26.237-14.14s-2.362,4.165-1.925,12.571" />
            <path
              className="CE Parented fill-disc-light"
              d="M1666.4,1178.889q.816.816,1.686,1.554c6.31,5.366,14.5,7.685,21.328,8.643a62.173,62.173,0,0,0,13.318.468s2.313-23.354-10.665-36.332-36.333-10.666-36.333-10.666S1653.42,1165.91,1666.4,1178.889Z"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.FourSquare, () => (
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.6665 1.1709H0.666504V4.1709H3.6665V1.1709Z"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 1.1709H6V4.1709H9V1.1709Z"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 6.50439H6V9.50439H9V6.50439Z"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.6665 6.50439H0.666504V9.50439H3.6665V6.50439Z"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.GroupSparkle, () => (
      <svg viewBox="0 0 118.507 134.993">
        <g transform="translate(-1314 -2436)">
          <g transform="translate(1315 2437)">
            <path
              className="CE Parented fill-disc-light"
              d="M7390.287,942.6a11.5,11.5,0,1,1-11.5-11.5A11.5,11.5,0,0,1,7390.287,942.6Z"
              transform="translate(-7320.952 -911.346)"
            />
            <circle
              className="CE Parented fill-disc-light"
              cx="9.368"
              cy="9.368"
              r="9.368"
              transform="translate(6.135 17.951)"
            />
            <circle
              className="CE Parented fill-disc-light"
              cx="9.368"
              cy="9.368"
              r="9.368"
              transform="translate(89.986 17.951)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M7355.671,947.3h-15.626a7.1,7.1,0,0,0-7.1,7.273l.374,15.369c.359,2.7,2.919,4.76,6.083,5.059l1.444,34.231h14.257l2.472-34.237c3.124-.328,5.643-2.38,6-5.053l.374-15.369a7.1,7.1,0,0,0-7.1-7.273Z"
              transform="translate(-7332.947 -905.688)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M7417.816,947.3h-15.627a7.1,7.1,0,0,0-7.1,7.273l.376,15.369c.358,2.7,2.919,4.76,6.083,5.059l1.444,34.231h14.256l2.474-34.237c3.123-.328,5.643-2.38,6-5.053l.373-15.369a7.1,7.1,0,0,0-7.1-7.273Z"
              transform="translate(-7311.239 -905.688)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M7390.838,952h-20.9a9.5,9.5,0,0,0-9.495,9.729l.5,20.557c.48,3.607,3.9,6.368,8.137,6.768l1.93,45.788h19.071l3.306-45.8c4.179-.437,7.548-3.183,8.026-6.76l.5-20.557A9.5,9.5,0,0,0,7392.42,952Z"
              transform="translate(-7323.342 -904.046)"
            />
            <path
              className="CE Parented fill-disc"
              d="M7371.777,933.18l-2.877.557a6.747,6.747,0,0,0-5.358,5.436l-.489,2.73-.665-2.989a6.749,6.749,0,0,0-5.2-5.138l-2.855-.6,2.721-.475a6.746,6.746,0,0,0,5.473-5.409l.53-2.84.437,2.619a6.746,6.746,0,0,0,5.313,5.5Z"
              transform="translate(-7325.477 -913.668)"
            />
            <path
              className="CE Parented fill-disc"
              d="M7404.948,928.363l-3.926.761a9.207,9.207,0,0,0-7.311,7.417l-.667,3.725-.907-4.078a9.2,9.2,0,0,0-7.1-7.01l-3.9-.816,3.712-.646a9.2,9.2,0,0,0,7.467-7.379l.724-3.877.6,3.573a9.205,9.205,0,0,0,7.25,7.506Z"
              transform="translate(-7316.111 -916.461)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Trophy, () => (
      <svg viewBox="0 0 96.622 94.632">
        <g transform="translate(-732.008 -416.873)">
          <g
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          >
            <path d="M18.485,6.084v33.4a31.571,31.571,0,1,0,63.142,0V6.084Z" />
            <path d="M59.332,69.627c-3.663,9.341,0,18.682,0,18.682H40.65s4.665-9.341,0-18.682" />
            <path d="M75.955,98.716H23.6l4.9-10.407H71.052Z" />
            <path d="M81.882,17.334H97.311c0,19.479-10.048,35.271-18.569,35.271" />
            <path d="M82.066,23.876h7.52c0,10.637-3.515,16.774-7.685,19.261" />
            <path d="M18.118,17.334H2.689C2.689,36.813,12.737,52.6,21.258,52.6" />
            <path d="M17.539,24.34h-7.52c0,10.637,3.515,16.773,7.685,19.261" />
          </g>
          <path
            className="CE Parented fill-disc"
            d="M50.756,15.813l5.749,11.648a.78.78,0,0,0,.587.427l12.854,1.867a.781.781,0,0,1,.433,1.332l-9.3,9.066a.783.783,0,0,0-.225.691l2.2,12.8a.781.781,0,0,1-1.133.823l-11.5-6.044a.777.777,0,0,0-.726,0L38.2,54.469a.78.78,0,0,1-1.132-.823l2.195-12.8a.78.78,0,0,0-.224-.691l-9.3-9.066a.781.781,0,0,1,.432-1.332L43.02,27.888a.783.783,0,0,0,.588-.427l5.748-11.648A.781.781,0,0,1,50.756,15.813Z"
            transform="translate(730.319 411.789)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Trees, () => (
      <svg viewBox="0 0 103.696 85.418">
        <g transform="translate(-729.319 -654.485)">
          <g
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          >
            <line x2="19.089" transform="translate(17.041 320.242)" />
            <path d="M26.585,290.959" />
            <line x1="7.224" y1="7.224" transform="translate(14.086 278.815)" />
            <path d="M26.506,278.441" />
            <line x1="4.364" y1="4.364" transform="translate(19.72 271.851)" />
            <path d="M39.34,254.38c-1.525-6.157-6.658-10.684-12.755-10.684-7.3,0-13.215,6.488-13.215,14.492a15.988,15.988,0,0,0,.209,2.5C5.764,262.97,0,270.765,0,280.056c0,11.086,8.195,20.073,18.305,20.073h7.9" />
          </g>
          <line y1="19.324" transform="translate(756.219 711.918)" />
          <line y1="19.324" transform="translate(803.116 711.918)" />
          <g
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          >
            <line x2="19.089" transform="translate(38.727 327.114)" />
            <line y2="64.859" transform="translate(48.271 262.255)" />
            <line
              y1="12.144"
              x2="12.144"
              transform="translate(48.271 285.686)"
            />
            <line
              x1="12.144"
              y1="12.144"
              transform="translate(35.772 285.686)"
            />
            <line y1="6.687" x2="6.687" transform="translate(48.191 278.626)" />
            <line x1="6.591" y1="6.591" transform="translate(41.406 278.723)" />
            <path d="M61.332,267.158a15.757,15.757,0,0,0,.154-2.1c0-8-5.916-14.492-13.215-14.492s-13.215,6.489-13.215,14.492a15.857,15.857,0,0,0,.209,2.495c-7.815,2.288-13.579,10.082-13.579,19.373,0,11.086,8.195,20.073,18.3,20.073H58.261c10.109,0,18.305-8.987,18.305-20.073C76.566,276.991,69.976,268.763,61.332,267.158Z" />
          </g>
          <g
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          >
            <line x1="19.089" transform="translate(62.567 320.242)" />
            <path d="M72.111,290.959" />
            <line y1="7.224" x2="7.224" transform="translate(77.386 278.815)" />
            <path d="M72.191,278.441" />
            <line y1="4.364" x2="4.364" transform="translate(74.612 271.851)" />
            <path d="M58.686,256.137c1.674-8.191,7.328-12.441,13.425-12.441,7.3,0,13.215,6.488,13.215,14.492a15.867,15.867,0,0,1-.209,2.5C92.932,262.97,98.7,270.765,98.7,280.056c0,11.086-8.195,20.073-18.3,20.073h-7.9" />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Dollar, () => (
      <svg viewBox="0 0 99.834 50.255">
        <g transform="translate(-730.903 -1051.89)">
          <circle
            cx="13.41"
            cy="13.41"
            r="13.41"
            transform="translate(766.909 1063.095)"
          />
          <circle
            cx="3.302"
            cy="3.302"
            r="3.302"
            transform="translate(742.335 1073.203)"
          />
          <rect
            width="96.833"
            height="47.253"
            transform="translate(731.903 1052.89)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M94.322,653.381c-5.353,0-7.986-4.11-7.918-8.17H13.385a7.586,7.586,0,0,1-7.313,7.463v22.458c5.146.184,7.663,4.247,7.557,8.242h73.01c-.105-4.089,2.531-8.259,7.914-8.259h.012V653.369C94.483,653.371,94.406,653.381,94.322,653.381Z"
            transform="translate(730.319 411.66)"
          />
          <circle
            cx="3.302"
            cy="3.302"
            r="3.302"
            transform="translate(810.41 1073.203)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Weight, () => (
      <svg viewBox="0 0 135.283 142.113">
        <g transform="translate(-106.586 -67.586)">
          <g transform="translate(108 69)">
            <path
              d="M2.27,0h7.38a2.27,2.27,0,0,1,2.27,2.27V9.038a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.27A2.27,2.27,0,0,1,2.27,0Z"
              transform="translate(113.153 12.428) rotate(45)"
            />
            <path
              d="M2.27,0h7.38a2.27,2.27,0,0,1,2.27,2.27V9.038a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.27A2.27,2.27,0,0,1,2.27,0Z"
              transform="translate(21.308 121.129) rotate(-135)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="64.083"
              height="13.725"
              rx="1.781"
              transform="translate(87.14 0) rotate(45)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="75.596"
              height="13.725"
              rx="1.781"
              transform="translate(73.18 5.82) rotate(45)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="64.083"
              height="13.725"
              rx="1.781"
              transform="translate(45.314 132.454) rotate(-135)"
            />
            <rect
              className="CE Parented fill-disc-light"
              width="75.596"
              height="13.725"
              rx="1.781"
              transform="translate(59.274 126.635) rotate(-135)"
            />
            <path
              className="CE Parented fill-disc"
              d="M3561.078,1415.071l4.7,4.7,3.564,3.563-47.673,47.672-8.267-8.267Z"
              transform="translate(-3474.15 -1376.094)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.HeartHandshake, () => (
      <svg viewBox="0 0 98.629 89.9">
        <g transform="translate(-1834.043 -636.571)">
          <g transform="translate(179.114 373.638)">
            <path d="M1710.622,289.824c9.032,8.074,18.038,16.178,27.1,24.222,1.8,1.6,2.91,3.347,2.4,5.819-.85,4.142-5.375,5.379-8.95,2.249-8.185-7.166-16.255-14.463-24.372-21.706-1.014-.906-2.008-1.836-3.044-2.715" />
            <path d="M1730.433,321.332c1.8,1.6,2.91,3.347,2.4,5.819-.85,4.142-5.375,5.379-8.95,2.249-8.185-7.166-16.255-14.462-24.372-21.706-1.014-.906-2.008-1.836-3.045-2.715" />
            <path d="M1722.925,328.84c1.8,1.6,2.91,3.346,2.4,5.819-.85,4.142-5.245,6.626-10.614.829" />
            <path d="M1668.692,317.545c-4.994-4.859-12.537-16.741-12.557-24.366a28.705,28.705,0,0,1,41.136-25.951" />
            <path d="M1702.785,283.774c-3.665,4.2-7.515,8.234-11.308,12.318a22.683,22.683,0,0,1-5.523,4.66,6.673,6.673,0,0,1-5.176.735c-3.318-.934-3.758-2.546-1.419-5.027,7.285-7.732,14.7-15.348,21.826-23.219,7.4-8.166,16.571-10.1,26.712-7.577,20.014,4.979,28.106,29.008,15.48,45.3-.569.734-1.234,1.394-2.3,2.593" />
            <rect
              width="11.887"
              height="22.598"
              rx="4.681"
              transform="matrix(-0.755, -0.656, 0.656, -0.755, 1701.473, 351.131)"
            />
            <rect
              width="11.887"
              height="25.633"
              rx="4.681"
              transform="matrix(-0.755, -0.656, 0.656, -0.755, 1691.018, 345.43)"
            />
            <rect
              width="11.887"
              height="25.633"
              rx="4.681"
              transform="translate(1682.075 337.991) rotate(-138.984)"
            />
            <rect
              width="11.887"
              height="20.754"
              rx="4.681"
              transform="matrix(-0.755, -0.656, 0.656, -0.755, 1673.76, 329.83)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.PersonCenter, () => (
      <svg viewBox="0 0 93.591 90.224">
        <g transform="translate(-731.578 -1388.58)">
          <circle
            className="CE Parented fill-disc"
            cx="7.405"
            cy="7.405"
            r="7.405"
            transform="translate(771.265 1389.548)"
          />
          <path
            className="CE Parented fill-disc"
            d="M54.058,996.459H41.706a5.611,5.611,0,0,0-5.609,5.748l.295,12.148c.285,2.131,2.31,3.764,4.809,4l1.141,27.057H53.61l1.954-27.062a5.05,5.05,0,0,0,4.743-3.994l.3-12.148a5.611,5.611,0,0,0-5.609-5.748Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className="CE Parented fill-disc-light"
            d="M57.351,1037.5c7.595,1.337,12.865,4.262,12.865,7.654,0,4.656-9.922,8.43-22.162,8.43s-22.161-3.774-22.161-8.43c0-3.332,5.082-6.212,12.458-7.58"
            transform="translate(730.319 411.789)"
          />
          <path
            d="M60.535,1028.415c19.217,2.273,33.3,9.664,33.3,18.432,0,10.577-20.5,19.152-45.78,19.152s-45.78-8.575-45.78-19.152c0-9.077,15.093-16.679,35.352-18.653"
            transform="translate(730.319 411.789)"
          />
        </g>
      </svg>
    ))
    .with(Icons.PersonCheckmark, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M12.3 13.5501V12.3001C12.3 10.9501 11.2 9.8501 9.84999 9.8501H4.95C3.6 9.8501 2.5 10.9501 2.5 12.3001V13.5501"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.40004 7.7C9.25019 7.7 10.75 6.20015 10.75 4.35C10.75 2.49984 9.25019 1 7.40004 1C5.54989 1 4.05005 2.49984 4.05005 4.35C4.05005 6.20015 5.54989 7.7 7.40004 7.7Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.80005 4.69995L7.20004 6.09995L9.10004 3.19995"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Dog, () => (
      <svg viewBox="0 0 98.14 88.719">
        <g transform="translate(-732.24 -1502.842)">
          <g
            transform="translate(730.319 411.789)"
            className="CE Parented fill-disc-light"
          >
            <path d="M96.912,1103.27a1.791,1.791,0,0,0-1.773-1.539H87.355c-2.2-6.339-6.9-9.678-13.656-9.678-4.5,0-9.571,1.447-12.539,6.947-.018.032-.041.06-.057.094s-.026.064-.041.1c-1.511,2.894-2.454,6.878-2.454,12.322,0,6.777-4.422,8.86-12.9,12.247-13.021,5.2-31.336,18.974-31.336,45.86-2.314-.052-4.383-.716-5.394-3.495-1.313-3.6.277-7.721.06-9.789a2.257,2.257,0,0,0-3.922-1.391c-1.261,1.24-1.882,5.773-2.012,6.752-.04.3-.922,7.318,3.168,11.988,2.29,2.616,5.6,3.942,9.856,3.942H72.521a1.806,1.806,0,0,0,.432-.062c.017,0,.033,0,.05-.007.033-.009.061-.026.093-.037a1.741,1.741,0,0,0,.178-.071c.052-.024.1-.052.148-.08s.1-.062.147-.1.091-.072.135-.111.082-.077.12-.119a1.54,1.54,0,0,0,.109-.132,1.576,1.576,0,0,0,.1-.143c.029-.048.055-.1.08-.148s.049-.105.07-.16.037-.111.052-.168.027-.108.036-.163a1.81,1.81,0,0,0,.02-.195c0-.033.009-.064.009-.1s-.005-.042-.006-.063a1.711,1.711,0,0,0-.019-.188,1.815,1.815,0,0,0-.032-.179c-.006-.021-.006-.043-.012-.064-.017-.058-.041-.1-.058-.162-.007-.017-.012-.036-.02-.053a11.488,11.488,0,0,0-6.836-7.468l3.23-15.428a8.3,8.3,0,0,0,4.852-2.6c5.475-5.816,4.642-19.566,4.286-25.438-.051-.835-.091-1.467-.091-1.827,0-5.207,2.639-7.738,8.068-7.738a8.87,8.87,0,0,0,7.053-2.852C97.608,1108.44,96.942,1103.479,96.912,1103.27Z" />
            <path d="M57.75,1177.626v-32.465l4.615-6.092" />
            <path d="M72.149,1105.843c0,2.739-5.907,9.229-5.907,9.229s-7.431-4.717-6.669-11.957" />
            <path d="M25.374,1154.944s13.179.4,13.179,14.042c0,0,10.342,1.788,11.447,8.64" />
          </g>
        </g>
      </svg>
    ))
    .with(Icons.Lock, () => (
      <svg viewBox="0 0 62.575 96.465">
        <g transform="translate(-750.338 -1623.326)">
          <path
            className="CE Parented fill-disc-light"
            d="M0,0H58.576a0,0,0,0,1,0,0V29.288A29.288,29.288,0,0,1,29.288,58.576h0A29.288,29.288,0,0,1,0,29.288V0A0,0,0,0,1,0,0Z"
            transform="translate(751.338 1658.215)"
          />
          <path
            className="CE Parented fill-disc"
            d="M58.933,1268.77a8.627,8.627,0,1,0-13.2,7.3v7.285a4.578,4.578,0,0,0,9.157,0v-7.285A8.612,8.612,0,0,0,58.933,1268.77Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className="CE Parented fill-disc"
            d="M68.932,1246.426v-14.957A18.932,18.932,0,0,0,50,1212.537h0a18.932,18.932,0,0,0-18.932,18.932v14.957h7.478v-12.559c0-7.252,5.128-13.131,11.454-13.131h0c6.326,0,11.454,5.879,11.454,13.131v12.559Z"
            transform="translate(730.319 411.789)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Gear, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M7.2999 9.3C8.34925 9.3 9.1999 8.44934 9.1999 7.39999C9.1999 6.35065 8.34925 5.5 7.2999 5.5C6.25056 5.5 5.3999 6.35065 5.3999 7.39999C5.3999 8.44934 6.25056 9.3 7.2999 9.3Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.95 9.3C11.8 9.7 11.85 10.15 12.15 10.45L12.2 10.5C12.7 11 12.7 11.8 12.2 12.25C11.7 12.75 10.9 12.75 10.45 12.25L10.4 12.2C10 11.8 9.35 11.8 8.95 12.2C8.75 12.4 8.65 12.65 8.65 12.9V13C8.65 13.7 8.1 14.25 7.4 14.25C6.7 14.25 6.15 13.7 6.15 13V12.95C6.15 12.55 5.85 12.15 5.45 12C5.05 11.85 4.6 11.9 4.3 12.2L4.25 12.25C3.75 12.75 2.95 12.75 2.5 12.25C2 11.75 2 10.95 2.5 10.5L2.55 10.45C2.95 10.05 2.95 9.4 2.55 9C2.35 8.8 2.1 8.7 1.85 8.7H1.75C1.05 8.7 0.5 8.15 0.5 7.45C0.5 6.75 1.05 6.2 1.75 6.2H1.8C2.2 6.2 2.6 5.9 2.75 5.5C2.9 5.1 2.85 4.65 2.55 4.35L2.5 4.3C2 3.8 2 3 2.5 2.55C3 2.1 3.75 2.1 4.25 2.55L4.3 2.6C4.6 2.9 5.05 3 5.45 2.8H5.55C5.95 2.65 6.15 2.25 6.2 1.85V1.75C6.2 1.05 6.75 0.5 7.45 0.5C8.15 0.5 8.7 1.05 8.7 1.75V1.8C8.7 2.35 9.15 2.85 9.75 2.85C10 2.85 10.3 2.75 10.45 2.55L10.5 2.5C11 2 11.8 2 12.25 2.5C12.75 3 12.75 3.8 12.25 4.25L12.2 4.3C11.9 4.6 11.8 5.05 12 5.45V5.5C12.15 5.9 12.55 6.09999 12.95 6.14999H13.05C13.75 6.14999 14.3 6.69999 14.3 7.39999C14.3 8.09999 13.75 8.64999 13.05 8.64999H12.9C12.45 8.64999 12.1 8.9 11.95 9.3Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.GearCheck, () => (
      <svg viewBox="0 0 98.019 98.019">
        <g transform="translate(-732.26 -1868.693)">
          <path
            className="CE Parented fill-disc-light"
            d="M96.96,1512.342v-14.857H83.715a34.4,34.4,0,0,0-4.675-11.155l9.4-9.4L77.938,1466.42l-9.44,9.44a34.38,34.38,0,0,0-11.119-4.6V1457.9H42.521v13.354a34.39,34.39,0,0,0-11.119,4.6l-9.44-9.44-10.506,10.506,9.4,9.4a34.4,34.4,0,0,0-4.675,11.155H2.941v14.857H16.11a34.359,34.359,0,0,0,4.64,11.266l-9.294,9.294,10.506,10.505,9.258-9.257a34.4,34.4,0,0,0,11.3,4.713v13.06H57.379v-13.06a34.4,34.4,0,0,0,11.3-4.713l9.257,9.257L88.444,1532.9l-9.293-9.294a34.381,34.381,0,0,0,4.64-11.266Z"
            transform="translate(730.319 411.789)"
          />
          <circle
            cx="22.395"
            cy="22.395"
            r="22.395"
            transform="translate(757.874 1894.459)"
          />
          <path
            className="CE Parented fill-disc"
            d="M63.295,1494.247,49.3,1519.671l-13.028-8.479,2.8-4.429,8.076,4.575,10.708-19.947Z"
            transform="translate(730.319 411.789)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Info, () => (
      <svg viewBox="0 0 24 24" strokeLinecap="round">
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="16" x2="12" y2="12" />
        <line x1="12" y1="8" x2="12" y2="8" />
      </svg>
    ))
    .with(Icons.Verified, () => (
      <svg viewBox="0 0 24 24">
        <path
          style={{ fill: '#2CA7E4', stroke: 'none' }}
          d="M10.11.84a2.56,2.56,0,0,1,3.79,0,7,7,0,0,0,4.66,1.93,2.56,2.56,0,0,1,2.68,2.68,7,7,0,0,0,1.93,4.66,2.56,2.56,0,0,1,0,3.79,7,7,0,0,0-1.93,4.66,2.56,2.56,0,0,1-2.68,2.68,7,7,0,0,0-4.66,1.93,2.56,2.56,0,0,1-3.79,0,7,7,0,0,0-4.66-1.93,2.56,2.56,0,0,1-2.68-2.68A7,7,0,0,0,.84,13.89a2.56,2.56,0,0,1,0-3.79A7,7,0,0,0,2.77,5.44,2.56,2.56,0,0,1,5.44,2.77,7,7,0,0,0,10.11.84Z"
        />
        <polyline
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
          points="17 8 10 16 7 13"
        />
      </svg>
    ))
    .with(Icons.MinimalLock, () => (
      <svg viewBox="0 0 24 24">
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
      </svg>
    ))
    .with(Icons.Edit, () => (
      <svg viewBox="0 0 30 30">
        <path d="M13.74,3.82H4.32a2.69,2.69,0,0,0-2.69,2.7V25.37a2.68,2.68,0,0,0,2.69,2.69H23.17a2.68,2.68,0,0,0,2.69-2.69V15.94" />
        <path d="M23.84,1.8a2.86,2.86,0,0,1,4,4L15.09,18.64,9.7,20l1.35-5.38Z" />
      </svg>
    ))
    .with(Icons.Star, () => (
      <svg viewBox="0 0 30 30">
        <path d="M15.22,2l4.17,8.45,9.33,1.37L22,18.39l1.59,9.29-8.34-4.39L6.87,27.68l1.6-9.29L1.72,11.82l9.33-1.37Z" />
      </svg>
    ))
    .with(Icons.CheckMarkOutOfCircle, () => (
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3598 12.1197C18.7598 13.0797 18.9998 14.1997 18.9198 15.2397C18.9198 19.9597 15.0798 23.7997 10.3598 23.7997C5.6398 23.7997 1.7998 19.9597 1.7998 15.2397C1.7998 10.5197 5.6398 6.67969 10.3598 6.67969C12.0398 6.67969 13.7198 7.1597 15.1598 8.1197"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.6796 5.63982L10.9196 19.3998L4.59961 15.2398L7.79961 12.0398L10.9996 15.3198L20.3596 1.7998L24.6796 5.63982Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Clipboard, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M9.9 2.25023H11.15C11.85 2.20023 12.5 2.75018 12.55 3.45018V13.2502C12.5 13.9502 11.85 14.5002 11.15 14.4502H3.9C3.2 14.5002 2.55 13.9502 2.5 13.2502V3.45018C2.55 2.75018 3.2 2.20023 3.9 2.25023H5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.8001 1H5.6001V3.25H9.8001V1Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Close, () => (
      <svg viewBox="0 0 24, 24">
        <g transform="translate(10.628 -7.799) rotate(45)">
          <line y2="26.06" transform="translate(13.03 0)" />
          <line y2="26.06" transform="translate(26.06 13.03) rotate(90)" />
        </g>
      </svg>
    ))
    .with(Icons.Trash, () => (
      <svg viewBox="0 0 24 24">
        <polyline points="3 6 5 6 21 6" />
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
        <line x1="10" y1="11" x2="10" y2="17" />
        <line x1="14" y1="11" x2="14" y2="17" />
      </svg>
    ))
    .with(Icons.CheckmarkCircle, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <circle
          cx={12}
          cy={12}
          r={11}
          fill="currentColor"
          stroke="currentColor"
        />
        <polygon
          points="9.9 18.4 3.9 12.4 5.6 10.8 9.9 15 18.2 6.7 19.9 8.4 9.9 18.4"
          fill="currentColor"
          stroke="currentColor"
        ></polygon>
      </svg>
    ))
    .with(Icons.CheckmarkCircleMasked, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <mask id="check-mask">
          <rect width="24" height="24" fill="white" />
          <polygon
            points="9.9 18.4 3.9 12.4 5.6 10.8 9.9 15 18.2 6.7 19.9 8.4 9.9 18.4"
            fill="black"
            stroke="none"
          />
        </mask>
        <circle
          cx="12"
          cy="12"
          r="11"
          stroke="none"
          fill="currentColor"
          mask="url(#check-mask)"
        />
      </svg>
    ))
    .with(Icons.Podium, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
        <path
          d="M10 22.5352H1V27.4352H10V22.5352Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 13.5352H10V27.5352H19V13.5352Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28 17.5352H19V27.4352H28V17.5352Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7001 2.53516L16.0001 5.23516L19.0001 5.63516L16.9001 7.73516L17.4001 10.6352L14.8001 9.23516L12.2001 10.6352L12.7001 7.73516L10.6001 5.63516L13.6001 5.23516L14.7001 2.53516Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Nodes, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32" fill="none">
        <path
          d="M22.9998 10.9352C25.3194 10.9352 27.1998 9.05476 27.1998 6.73517C27.1998 4.41557 25.3194 2.53516 22.9998 2.53516C20.6802 2.53516 18.7998 4.41557 18.7998 6.73517C18.7998 9.05476 20.6802 10.9352 22.9998 10.9352Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.20001 20.735C8.51961 20.735 10.4 18.8546 10.4 16.535C10.4 14.2154 8.51961 12.335 6.20001 12.335C3.88042 12.335 2 14.2154 2 16.535C2 18.8546 3.88042 20.735 6.20001 20.735Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.9998 30.4352C25.3194 30.4352 27.1998 28.5548 27.1998 26.2352C27.1998 23.9156 25.3194 22.0352 22.9998 22.0352C20.6802 22.0352 18.7998 23.9156 18.7998 26.2352C18.7998 28.5548 20.6802 30.4352 22.9998 30.4352Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.7998 18.6348L19.3998 24.1348"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3998 8.83496L9.7998 14.335"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.NewsPaper, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M9.069 2.672v14.928h-6.397c0 0 0 6.589 0 8.718s1.983 3.010 3.452 3.010c1.469 0 16.26 0 20.006 0 1.616 0 3.199-1.572 3.199-3.199 0-1.175 0-23.457 0-23.457h-20.259zM6.124 28.262c-0.664 0-2.385-0.349-2.385-1.944v-7.652h5.331v7.192c0 0.714-0.933 2.404-2.404 2.404h-0.542zM28.262 26.129c0 1.036-1.096 2.133-2.133 2.133h-17.113c0.718-0.748 1.119-1.731 1.119-2.404v-22.12h18.126v22.391z"></path>
        <path d="M12.268 23.997h13.861v1.066h-13.861v-1.066z"></path>
        <path d="M26.129 9.602h-13.861v7.997h13.861v-7.997zM25.063 16.533h-11.729v-5.864h11.729v5.864z"></path>
      </svg>
    ))
    .with(Icons.Monitor, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <rect x="1.72" y="1.97" width="27" height="18.9" rx="2.7" />
        <line x1="9.82" y1="26.27" x2="20.62" y2="26.27" />
        <line x1="15.22" y1="20.87" x2="15.22" y2="26.27" />
      </svg>
    ))
    .with(Icons.Directions, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
        <path
          d="M26.1001 5.43555C21.1001 11.5355 16.6001 19.5356 14.9001 27.2356C14.9001 27.2356 11.0001 14.4355 4.1001 5.43555"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0001 12.3352L26.9001 4.53516L19.1001 5.63525"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 12.3352L3.10001 4.53516L10.9 5.63525"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.DollarSign, () => (
      <svg viewBox="0 0 24 24">
        <line x1="12" y1="1" x2="12" y2="23"></line>
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
      </svg>
    ))
    .with(Icons.Negotiate, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
        <path
          d="M4.24512 5.19725L16.8451 22.4973L20.1451 23.7972L20.3451 20.1972L8.04512 2.59727C8.04512 2.59727 4.74512 1.89725 4.24512 5.19725Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 28.8379H26.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 19.0371L8.2 24.3372"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.2 19.0371L3 24.3372"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.HandWave, () => (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5993 2.20141C12.1032 2.16542 13.2622 2.50375 13.8669 4.14502M11.8153 0.689697C12.8231 0.905654 14.9467 1.85586 15.3786 3.92904M4.7295 13.5359C4.22479 13.5578 3.07573 13.1872 2.51714 11.5297M4.4714 15.041C3.47003 14.797 1.37383 13.7878 1 11.7034M19.5897 2.95723L20.8855 10.5157C21.0654 11.5595 21.1662 13.9062 20.1296 14.9428L16.0264 18.938C15.3426 19.2979 13.5645 19.7154 11.9233 18.5061C10.8795 17.6782 8.53277 15.6771 7.49618 14.2949L4.47279 11.0556C3.96889 10.5877 3.13386 9.5223 3.82492 9.004C4.11286 8.78805 4.83992 8.5289 5.44459 9.21996L9.11585 12.1354L4.47279 7.27635C4.04088 6.88043 3.30662 5.98061 3.82492 5.5487C4.11286 5.22477 4.86151 4.79285 5.55257 5.65668L10.6275 10.2997L5.44459 4.36094C5.22864 4.03701 4.9047 3.30276 5.33662 2.95723C5.55257 2.66928 6.17885 2.28776 6.95629 3.0652L12.5712 8.46412L8.46798 3.82106C8.25203 3.46113 7.94969 2.6549 8.46798 2.30937C8.68394 2.05742 9.33181 1.79107 10.1956 2.74128L14.9467 7.27635C15.4146 7.70827 16.4152 8.20498 16.6743 6.73647C16.7823 5.69268 17.1278 3.47551 17.6461 2.95723C17.8981 2.6333 18.5315 2.07182 19.0498 2.41735C19.2113 2.48933 19.5453 2.69807 19.5897 2.95723Z"
          stroke="#1E749F"
          strokeWidth="1"
        />
      </svg>
    ))
    .with(Icons.SearchProfile, () => (
      <svg
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.1825 26.7342L22.116 18.8235C21.8019 19.4683 21.2364 20.1241 20.6256 20.7042L30.1825 30.2847C31.1479 29.2881 31.1479 27.7308 30.1825 26.7342Z"
          fill="#607986"
        />
        <path
          d="M26.632 30.2847C27.6286 31.2502 29.1858 31.2502 30.1825 30.2847L20.6256 20.7042C19.8611 21.4302 19.0259 22.0374 18.5241 22.3556L26.632 30.2847Z"
          fill="#607986"
        />
        <path
          d="M12.4923 23.9935C18.8394 23.9935 23.9847 18.8482 23.9847 12.5011C23.9847 6.15409 18.8394 1.00879 12.4923 1.00879C6.1453 1.00879 1 6.15409 1 12.5011C1 18.8482 6.1453 23.9935 12.4923 23.9935Z"
          fill="#B3BFC5"
          stroke="#607986"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 19.5087C4.18687 16.6434 4.62289 16.7248 4.87204 16.5379C5.86867 15.7904 7.05217 15.2921 8.2668 15.043C10.5715 17.4411 14.3711 17.5034 16.7693 15.1987L16.925 15.043C18.1396 15.2921 19.3231 15.7904 20.3197 16.5379C20.6 16.7248 20.782 16.5188 21 19.5087"
          fill="white"
        />
        <path
          d="M4 19.5087C4.18687 16.6434 4.62289 16.7248 4.87204 16.5379C5.86867 15.7904 7.05217 15.2921 8.2668 15.043C10.5715 17.4411 14.3711 17.5034 16.7693 15.1987L16.925 15.043C18.1396 15.2921 19.3231 15.7904 20.3197 16.5379C20.6 16.7248 20.782 16.5188 21 19.5087C21 19.5087 18 23.5087 12.5 23.5087C7 23.5087 4 19.5087 4 19.5087Z"
          fill="white"
        />
        <path
          d="M17.0495 8.19123C17.0495 10.5894 15.5234 14.1087 12.5958 14.1087C9.66825 14.1087 8.14217 10.5894 8.14217 8.19123C8.29789 5.73081 10.3846 3.86213 12.845 4.01786C15.0874 4.14243 16.8938 5.94882 17.0495 8.19123Z"
          fill="white"
        />
      </svg>
    ))
    .with(Icons.GroupWave, () => (
      <svg
        width="28"
        height="30"
        viewBox="0 0 28 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 4L10 5"
          stroke="#BFC9CE"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 4L18 5"
          stroke="#BFC9CE"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9997 2.76661V1"
          stroke="#BFC9CE"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.225 12.9619L20.2555 14.1687L20.2861 15.3754C20.3777 16.0169 20.9887 16.5363 21.7525 16.5974L22.0885 24.8764H25.5406L26.1516 16.5974C26.9154 16.5057 27.5264 16.0169 27.5875 15.3754L27.6791 11.6788C27.7097 10.7012 26.9153 9.9375 25.9683 9.9375H25.6934H23.1272"
          fill="#D9DFE2"
        />
        <path
          d="M20.2861 15.3754C20.3777 16.0169 20.9887 16.5363 21.7525 16.5974L22.0885 24.8764H25.5406L26.1516 16.5974C26.9154 16.5057 27.5264 16.0169 27.5875 15.3754L27.6791 11.6788C27.7097 10.7012 26.9153 9.9375 25.9683 9.9375H25.6934H23.1272C20.8544 9.9375 20.2861 10.5088 20.225 12.9619L20.2555 14.1687L20.2861 15.3754Z"
          fill="#D9DFE2"
        />
        <path
          d="M24.0107 8.53017C25.2593 8.53017 26.2714 7.51803 26.2714 6.26948C26.2714 5.02094 25.2593 4.00879 24.0107 4.00879C22.7622 4.00879 21.75 5.02094 21.75 6.26948C21.75 7.51803 22.7622 8.53017 24.0107 8.53017Z"
          fill="#D9DFE2"
        />
        <path
          d="M3.72796 8.53017C4.97651 8.53017 5.98862 7.51803 5.98862 6.26948C5.98862 5.02094 4.97651 4.00879 3.72796 4.00879C2.47942 4.00879 1.46722 5.02094 1.46722 6.26948C1.46722 7.51803 2.47942 8.53017 3.72796 8.53017Z"
          fill="#80949E"
        />
        <path
          d="M5.46936 9.90698H1.98663H1.71171C0.734113 9.90698 -0.0296645 10.7013 0.000885386 11.6483L0.09255 15.3449C0.1842 15.9864 0.795213 16.5058 1.55896 16.5669L1.89504 24.8459H5.34712L5.95812 16.5669C6.72186 16.4752 7.33292 15.9864 7.39402 15.3449L7.48561 11.6483C7.51616 10.6707 6.72191 9.90698 5.77486 9.90698H5.46936Z"
          fill="#80949E"
        />
        <path
          d="M13.9317 10.1819C15.4671 10.1819 16.7118 8.93724 16.7118 7.40187C16.7118 5.86649 15.4671 4.62183 13.9317 4.62183C12.3963 4.62183 11.1516 5.86649 11.1516 7.40187C11.1516 8.93724 12.3963 10.1819 13.9317 10.1819Z"
          fill="#607986"
        />
        <path
          d="M21.9052 7.31033C21.3553 6.91318 20.561 7.03538 20.1638 7.58528L17.2005 11.6484H16.1007H11.8237H11.4877C10.2962 11.6484 9.3492 12.626 9.37975 13.8175L9.50192 18.3694C9.59357 19.1637 10.3573 19.7747 11.3043 19.8663L11.7321 30.0089H13.84H15.948L16.9256 16.292L22.2107 9.05167C22.5773 8.50177 22.4551 7.70747 21.9052 7.31033Z"
          fill="#607986"
        />
      </svg>
    ))
    .with(Icons.LinkedInGray, () => (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_998_2643)">
          <path
            d="M21.5812 22.0649H17.8298V16.1833C17.8298 14.78 17.8007 12.9777 15.8756 12.9777C13.9201 12.9777 13.6212 14.4975 13.6212 16.0807V22.0624H9.86983V9.97741H13.473V11.6239H13.5212C13.8825 11.0075 14.4041 10.5005 15.0305 10.1569C15.6569 9.81321 16.3648 9.64571 17.0788 9.67218C20.8783 9.67218 21.5824 12.1735 21.5824 15.4297V22.0598L21.5812 22.0649ZM5.63339 8.32336C5.2026 8.32336 4.78149 8.19563 4.42328 7.95632C4.06508 7.71702 3.78587 7.37687 3.62095 6.9789C3.45604 6.58093 3.41283 6.14299 3.49678 5.72046C3.58073 5.29793 3.78807 4.90978 4.0926 4.60508C4.39712 4.30038 4.78515 4.09281 5.20764 4.00861C5.63012 3.92442 6.06808 3.96737 6.46615 4.13206C6.86421 4.29674 7.20452 4.57575 7.44403 4.93382C7.68355 5.29188 7.81152 5.71292 7.81177 6.14371C7.81211 6.42994 7.756 6.71341 7.64666 6.97793C7.53732 7.24244 7.37689 7.4828 7.17456 7.68525C6.97223 7.8877 6.73196 8.04826 6.46751 8.15776C6.20306 8.26725 5.91961 8.32353 5.63339 8.32336ZM7.51414 22.0649H3.75264V9.97741H7.51414V22.0649ZM23.4556 0.478659H1.86809C1.62559 0.475815 1.38491 0.520766 1.15978 0.610946C0.934659 0.701126 0.729503 0.834768 0.556032 1.00424C0.382561 1.17371 0.244171 1.3757 0.148767 1.59866C0.0533622 1.82162 0.002811 2.06119 0 2.30369L0 23.9836C0.002811 24.2261 0.0533622 24.4657 0.148767 24.6887C0.244171 24.9116 0.382561 25.1136 0.556032 25.2831C0.729503 25.4525 0.934659 25.5862 1.15978 25.6764C1.38491 25.7666 1.62559 25.8115 1.86809 25.8087H23.4543C23.9449 25.8147 24.4178 25.626 24.7695 25.2839C25.1211 24.9418 25.3227 24.4742 25.33 23.9836V2.30369C25.3227 1.81314 25.1211 1.34553 24.7695 1.0034C24.4178 0.661278 23.9449 0.472568 23.4543 0.478659H23.4556Z"
            fill="#B3BFC5"
          />
        </g>
        <defs>
          <clipPath id="clip0_998_2643">
            <rect
              width="25.33"
              height="25.33"
              fill="white"
              transform="translate(0 0.478516)"
            />
          </clipPath>
        </defs>
      </svg>
    ))
    .with(Icons.EmailGray, () => (
      <svg
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.748047" width="25.33" height="25.33" rx="4" fill="#B3BFC5" />
        <path
          d="M6.36504 7.44824H18.965C19.84 7.44824 20.54 8.14825 20.54 9.02325V18.4733C20.54 19.3483 19.84 20.0482 18.965 20.0482H6.36504C5.49004 20.0482 4.79004 19.3483 4.79004 18.4733V9.02325C4.79004 8.14825 5.49004 7.44824 6.36504 7.44824Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.54 9.02344L12.665 14.5067L4.79004 9.02344"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.DiscGray, () => (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0161133"
          width="25.3334"
          height="25.3334"
          rx="4"
          fill="#B3BFC5"
        />
        <path
          d="M12.6 21.2161C17.3496 21.2161 21.2 17.3659 21.2 12.6162C21.2 7.86656 17.3496 4.01611 12.6 4.01611C7.85035 4.01611 4 7.86656 4 12.6162C4 17.3659 7.85035 21.2161 12.6 21.2161Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M12.6001 14.4827C13.631 14.4827 14.4668 13.6469 14.4668 12.616C14.4668 11.585 13.631 10.7493 12.6001 10.7493C11.5692 10.7493 10.7334 11.585 10.7334 12.616C10.7334 13.6469 11.5692 14.4827 12.6001 14.4827Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M12.6667 10.6828L12.6667 4.01611"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M12.6667 21.3495L12.6667 14.6829"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M14.6667 12.6829L21.3334 12.6829"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M4.00002 12.6829L10.6667 12.6829"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    ))
    .with(Icons.UserGray, () => (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.287354" width="25.33" height="25.33" rx="4" fill="#B3BFC5" />
        <path
          d="M20.165 21.8266V19.9435C20.165 17.8656 18.4767 16.1772 16.3988 16.1772H8.93123C6.85331 16.1772 5.165 17.8656 5.165 19.9435V21.8266"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6973 12.4761C14.7774 12.4761 16.4636 10.7898 16.4636 8.70979C16.4636 6.62976 14.7774 4.9436 12.6973 4.9436C10.6173 4.9436 8.93111 6.62976 8.93111 8.70979C8.93111 10.7898 10.6173 12.4761 12.6973 12.4761Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.CalendarDetailed, () => (
      <svg
        width="37"
        height="32"
        viewBox="0 0 37 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36 31H0.999999L1 9H36V31Z"
          fill="#D9DFE2"
          stroke="#D9DFE2"
          strokeWidth="2"
        />
        <path
          d="M13.8703 12.5894H11.426C11.1498 12.5894 10.926 12.8132 10.926 13.0894V15.5001C10.926 15.7763 11.1498 16.0001 11.426 16.0001H13.8703C14.1465 16.0001 14.3703 15.7763 14.3703 15.5001V13.0894C14.3703 12.8132 14.1465 12.5894 13.8703 12.5894Z"
          fill="white"
        />
        <path
          d="M20.0186 12.5894H17.5741C17.298 12.5894 17.0741 12.8132 17.0741 13.0894V15.5001C17.0741 15.7763 17.298 16.0001 17.5741 16.0001H20.0186C20.2947 16.0001 20.5186 15.7763 20.5186 15.5001V13.0894C20.5186 12.8132 20.2947 12.5894 20.0186 12.5894Z"
          fill="white"
        />
        <path
          d="M26.1666 12.5894H23.7221C23.446 12.5894 23.2221 12.8132 23.2221 13.0894V15.5001C23.2221 15.7763 23.446 16.0001 23.7221 16.0001H26.1666C26.4427 16.0001 26.6666 15.7763 26.6666 15.5001V13.0894C26.6666 12.8132 26.4427 12.5894 26.1666 12.5894Z"
          fill="white"
        />
        <path
          d="M32.3148 12.5894H29.8703C29.5942 12.5894 29.3703 12.8132 29.3703 13.0894V15.5001C29.3703 15.7763 29.5942 16.0001 29.8703 16.0001H32.3148C32.5909 16.0001 32.8148 15.7763 32.8148 15.5001V13.0894C32.8148 12.8132 32.5909 12.5894 32.3148 12.5894Z"
          fill="white"
        />
        <path
          d="M7.72223 18.2373H5.27777C5.00163 18.2373 4.77777 18.4612 4.77777 18.7373V21.1481C4.77777 21.4242 5.00163 21.6481 5.27777 21.6481H7.72223C7.99837 21.6481 8.22223 21.4242 8.22223 21.1481V18.7373C8.22223 18.4612 7.99837 18.2373 7.72223 18.2373Z"
          fill="white"
        />
        <path
          d="M13.8703 18.2373H11.426C11.1498 18.2373 10.926 18.4612 10.926 18.7373V21.1481C10.926 21.4242 11.1498 21.6481 11.426 21.6481H13.8703C14.1465 21.6481 14.3703 21.4242 14.3703 21.1481V18.7373C14.3703 18.4612 14.1465 18.2373 13.8703 18.2373Z"
          fill="white"
        />
        <path
          d="M20.0186 18.2373H17.5741C17.298 18.2373 17.0741 18.4612 17.0741 18.7373V21.1481C17.0741 21.4242 17.298 21.6481 17.5741 21.6481H20.0186C20.2947 21.6481 20.5186 21.4242 20.5186 21.1481V18.7373C20.5186 18.4612 20.2947 18.2373 20.0186 18.2373Z"
          fill="white"
        />
        <path
          d="M26.1666 18.2373H23.7221C23.446 18.2373 23.2221 18.4612 23.2221 18.7373V21.1481C23.2221 21.4242 23.446 21.6481 23.7221 21.6481H26.1666C26.4427 21.6481 26.6666 21.4242 26.6666 21.1481V18.7373C26.6666 18.4612 26.4427 18.2373 26.1666 18.2373Z"
          fill="white"
        />
        <path
          d="M32.3148 18.2373H29.8703C29.5942 18.2373 29.3703 18.4612 29.3703 18.7373V21.1481C29.3703 21.4242 29.5942 21.6481 29.8703 21.6481H32.3148C32.5909 21.6481 32.8148 21.4242 32.8148 21.1481V18.7373C32.8148 18.4612 32.5909 18.2373 32.3148 18.2373Z"
          fill="white"
        />
        <path
          d="M7.72223 23.9219H5.27777C5.00163 23.9219 4.77777 24.1457 4.77777 24.4219V26.8326C4.77777 27.1088 5.00163 27.3326 5.27777 27.3326H7.72223C7.99837 27.3326 8.22223 27.1088 8.22223 26.8326V24.4219C8.22223 24.1457 7.99837 23.9219 7.72223 23.9219Z"
          fill="white"
        />
        <path
          d="M13.8703 23.9219H11.426C11.1498 23.9219 10.926 24.1457 10.926 24.4219V26.8326C10.926 27.1088 11.1498 27.3326 11.426 27.3326H13.8703C14.1465 27.3326 14.3703 27.1088 14.3703 26.8326V24.4219C14.3703 24.1457 14.1465 23.9219 13.8703 23.9219Z"
          fill="white"
        />
        <path
          d="M20.0186 23.9219H17.5741C17.298 23.9219 17.0741 24.1457 17.0741 24.4219V26.8326C17.0741 27.1088 17.298 27.3326 17.5741 27.3326H20.0186C20.2947 27.3326 20.5186 27.1088 20.5186 26.8326V24.4219C20.5186 24.1457 20.2947 23.9219 20.0186 23.9219Z"
          fill="white"
        />
        <path
          d="M26.1666 23.9219H23.7221C23.446 23.9219 23.2221 24.1457 23.2221 24.4219V26.8326C23.2221 27.1088 23.446 27.3326 23.7221 27.3326H26.1666C26.4427 27.3326 26.6666 27.1088 26.6666 26.8326V24.4219C26.6666 24.1457 26.4427 23.9219 26.1666 23.9219Z"
          fill="white"
        />
        <path
          d="M1 3H36V8H1V3Z"
          fill="#607986"
          stroke="#607986"
          strokeWidth="2"
        />
        <path
          d="M9.20371 0H8.05554C7.7794 0 7.55554 0.223858 7.55554 0.500001V3.42421C7.55554 3.70035 7.7794 3.92421 8.05554 3.92421H9.20371C9.47985 3.92421 9.70371 3.70035 9.70371 3.42421V0.5C9.70371 0.223858 9.47985 0 9.20371 0Z"
          fill="#D9DFE2"
        />
        <path
          d="M28.6481 0H27.5C27.2238 0 27 0.223858 27 0.500001V3.42421C27 3.70035 27.2238 3.92421 27.5 3.92421H28.6481C28.9243 3.92421 29.1481 3.70035 29.1481 3.42421V0.5C29.1481 0.223858 28.9243 0 28.6481 0Z"
          fill="#D9DFE2"
        />
      </svg>
    ))
    .with(Icons.Arrow, () => (
      <svg
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="9 18 15 12 9 6" />
      </svg>
    ))
    .with(Icons.Wrench, () => (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0166 5.30016C11.6833 5.63349 11.6833 6.23349 12.0166 6.56683L13.4832 8.0335C13.8166 8.36683 14.4166 8.36683 14.7499 8.0335L18.2166 4.56683C19.4833 7.30016 18.2166 10.5668 15.4832 11.8335C14.0166 12.5002 12.4166 12.5002 10.9499 11.8335L4.61659 18.1668C3.88326 18.9002 2.61659 18.9002 1.88325 18.1668C1.14992 17.4335 1.14992 16.1668 1.88325 15.4335L8.21658 9.10016C6.94992 6.36683 8.21659 3.10016 10.9499 1.8335C12.4166 1.16683 14.0166 1.16683 15.4832 1.8335L12.0166 5.30016Z"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Laptop, () => (
      <svg
        width="101"
        height="95"
        viewBox="0 0 101 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.6 22.5C61.3 22.5 69.2 30.4 69.2 40.1C69.2 49.8 61.3 57.7 51.6 57.7C41.9 57.7 34 49.8 34 40.1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.2001 36.3002C38.5001 36.3002 30.6001 36.3002 30.6001 36.3002C30.6001 26.6002 38.5001 18.7002 48.2001 18.7002V36.3002Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.1001 56.8002L51.6001 40.1002L69.1001 39.2002"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.6001 40.0998L64.6001 28.2998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.6999 9H11.3999V64.4H89.6999V9Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.2 81.8999H1L11.4 64.3999H89.7L99.2 81.8999Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2998 69.7998H84.9998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.2998 75.2002H14.2998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.1 81.8999H1V86.0999H99.1V81.8999Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.7998 12.7002H51.9998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.6 22.5C61.3 22.5 69.2 30.4 69.2 40.1C69.2 49.8 61.3 57.7 51.6 57.7C41.9 57.7 34 49.8 34 40.1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.6001 40.0998L64.6001 28.2998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.6999 9H11.3999V64.4H89.6999V9Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2998 69.7998H84.9998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.2998 75.2002H14.2998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.7998 12.7002H51.9998"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(Icons.Bubble, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 66.134 66.2"
      >
        <g transform="translate(0 0)">
          <path
            d="M60.684,51.246A33.064,33.064,0,1,0,8.52,55.228L1.3,66.2l31.842-.069v0A33.027,33.027,0,0,0,60.684,51.246Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    ))
    .with(Icons.Handshake, () => (
      <svg
        width="99"
        height="62"
        viewBox="0 0 99 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.6634 7.15721L18.9536 1.84912L0.99981 31.3087L9.7096 36.6168L27.6634 7.15721Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.6526 35.7675L97.3623 30.4595L79.4085 0.999922L70.6988 6.30798L88.6526 35.7675Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.9717 10.625L30.5717 12.925C30.5717 12.925 35.0717 13.425 37.4717 14.725"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2714 51.5251C22.5714 50.3251 22.1714 47.9251 23.3714 46.2251L29.3714 37.8251C30.5714 36.1251 32.9714 35.7251 34.6714 36.9251C36.3714 38.1251 36.7714 40.5251 35.5714 42.2251L29.5714 50.6251C28.3714 52.3251 25.9714 52.7251 24.2714 51.5251Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8715 57.1252C28.1715 55.9252 27.7715 53.5252 28.9715 51.8252L34.9715 43.4252C36.1715 41.7252 38.5715 41.3252 40.2715 42.5252C41.9715 43.7252 42.3715 46.1252 41.1715 47.8252L35.1715 56.2252C33.9715 57.9252 31.5715 58.3252 29.8715 57.1252Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.7712 43.0254C73.8712 44.2254 74.5713 47.3254 73.5713 49.0254C72.5713 50.7254 69.9713 51.2254 67.8713 49.9254L63.5713 47.3254"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.9717 41.2251L63.4717 47.3251C65.5717 48.5251 66.4717 51.0251 65.4717 52.8251C64.3717 54.6251 61.8716 55.1251 59.6716 53.9251L56.6716 52.2251"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.4713 46.2251L56.9713 52.3251C59.0713 53.5251 59.5713 56.1251 58.0713 58.0251C56.0713 60.6251 50.5713 59.3251 48.4713 58.1251L45.0713 56.3251"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.4716 59.7252C35.7716 58.5252 35.3716 56.1252 36.5716 54.4252L39.7716 49.9252C40.9716 48.2252 43.3716 47.8252 45.0716 49.0252C46.7716 50.2252 47.1716 52.6252 45.9716 54.3252L42.7716 58.8252C41.5716 60.5252 39.1716 60.9252 37.4716 59.7252Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0712 45.5249C17.3712 44.3249 16.9712 41.9249 18.1712 40.2249L20.3712 37.1249C21.5712 35.4249 23.9712 35.0249 25.6712 36.2249C27.3712 37.4249 27.7712 39.8249 26.5712 41.5249L24.3712 44.6249C23.1712 46.4249 20.7712 46.7249 19.0712 45.5249Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.3713 10.6254C71.3713 10.6254 68.7713 11.6254 64.4713 10.6254C60.2713 9.62539 51.5713 5.72539 41.7713 10.1254C41.7713 10.1254 35.3713 17.1254 31.5713 24.9254C31.5713 24.9254 35.8713 28.1254 41.7713 23.8254L47.1713 18.4254H49.1713L77.3713 36.1254C79.4713 37.3254 79.5713 40.7254 78.4713 42.6254C77.3713 44.4254 74.8713 44.9254 72.6713 43.7254L62.1713 37.6254"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.1714 37.025L85.2714 32.125"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0713 34.625L18.0713 38.225"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(null, () => null)
    .with(undefined, () => null)
    .exhaustive()

  return IconComponent
    ? React.cloneElement(IconComponent, {
        xlmns: 'http://www.w3.org/2000/svg',
        className: `${styles.svg} ${className} icon icon-${icon}`,
        style,
      })
    : null
}
